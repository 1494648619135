import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import Modal from '../Modal';
import { ieWarningMessages } from '../../defineMessages';
import s from './IEWarning.scss';
import Clickable from '../Clickable';
import { checkIfInternetExplorer } from '../../helpers/util';

function IEWarning({ intl: { formatMessage } }) {
  const [open, setOpen] = useState(checkIfInternetExplorer());

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Modal
      className={s.modal}
      maxWidth
      onClose={handleClose}
      isVisible={open}
      title={formatMessage({ ...ieWarningMessages.title })}
      triggerProps={{ className: s.hideButton }}
    >
      <div
        className={s.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage({ ...ieWarningMessages.body }),
        }}
      />
      <div className={s.bottom}>
        <Clickable
          label={formatMessage({
            ...ieWarningMessages.confirm,
          })}
          primary
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
}

IEWarning.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default withStyles(s)(injectIntl(IEWarning));
