import { combineReducers } from 'redux';
import user from './user';
import intl from './intl';
import header from './header';
import message from './message';
import loggedinUser from './loggedinUser';
import config from './config';
import shop from './shop';
import product from './product';
import basket from './basket';
import home from './home';
import greeting from './greeting';
import wrapping from './wrapping';
import giftcard from './giftcard';
import page from './page';
import error from './error';
import loading from './loading';

export default combineReducers({
  user,
  intl,
  header,
  message,
  loggedinUser,
  config,
  home,
  shop,
  product,
  basket,
  greeting,
  wrapping,
  giftcard,
  page,
  error,
  loading,
});
