import {
  DEFAULT_COUNTRY,
  DEFAULT_COUNTRY_NAMES,
  SHIPPING_METHODS,
  DELIVERY_CURRENCIES,
  THRESHOLD_CARD_VALUE,
} from '../constants/config';

const {
  DAO,
  PostNordParcel,
  Varubrev,
  REK,
  InternationalParcel,
} = SHIPPING_METHODS;
const { sek, nok, eur } = DELIVERY_CURRENCIES;
const { seThreshold, fiThreshold } = THRESHOLD_CARD_VALUE;

export function getFilteredQuote(sortedQuotes, existingString) {
  const quoteId = sortedQuotes.reduce((returnValue, currentValue) => {
    if (currentValue.displayText?.includes(existingString)) {
      return currentValue?.id;
    }
    return returnValue;
  }, '');

  return quoteId;
}

export function calculateQuoteId(
  recipientCountryCode,
  recipientCurrency,
  giftcardValueTotal,
  sortedQuotes,
) {
  let shippingQuoteId;
  if (recipientCountryCode === DEFAULT_COUNTRY) {
    shippingQuoteId = getFilteredQuote(sortedQuotes, DAO);
    return shippingQuoteId;
  }
  if (recipientCountryCode === DEFAULT_COUNTRY_NAMES.SE) {
    shippingQuoteId = getFilteredQuote(sortedQuotes, PostNordParcel);
    return shippingQuoteId;
  }
  shippingQuoteId = getFilteredQuote(sortedQuotes, InternationalParcel);
  if (!shippingQuoteId) {
    if (
      ((recipientCurrency === sek || recipientCurrency === nok) &&
        giftcardValueTotal < seThreshold) ||
      (recipientCurrency === eur && giftcardValueTotal < fiThreshold)
    ) {
      shippingQuoteId = getFilteredQuote(sortedQuotes, Varubrev);
    } else {
      shippingQuoteId = getFilteredQuote(sortedQuotes, REK);
    }
  }

  return shippingQuoteId;
}
