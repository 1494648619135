import {
  GET_AUHTORIZATION_SCHEME_START,
  GET_AUHTORIZATION_SCHEME_ERROR,
  GET_AUHTORIZATION_SCHEME_SUCCESS,
  GET_GIFTCARD_START,
  GET_GIFTCARD_ERROR,
  GET_GIFTCARD_SUCCESS,
  SET_GIFTCARD_VERIFICATION,
  GET_PDF_BUNDLE_START,
  GET_PDF_BUNDLE_ERROR,
  GET_PDF_BUNDLE_SUCCESS,
} from '../constants';

import { getProductPresentations } from '../helpers';

export default function giftcard(state = {}, action) {
  switch (action.type) {
    case GET_AUHTORIZATION_SCHEME_START:
    case GET_PDF_BUNDLE_START:
    case GET_GIFTCARD_START: {
      return { ...state, ...action.payload };
    }

    case GET_AUHTORIZATION_SCHEME_SUCCESS: {
      const {
        authorizationScheme,
        loading,
        exportType,
        token,
      } = action.payload;

      return {
        ...state,
        authorizationScheme,
        exportType,
        token,
        loading,
      };
    }

    case GET_GIFTCARD_SUCCESS: {
      const {
        exportBase64,
        exportType,
        loading,
        id,
        productId,
        productTitle,
        errors,
        ...rest
      } = action.payload;

      const byProduct = {
        ...(state.byProduct || {}),
      };
      const byToken = {
        ...(state.byToken || {}),
      };
      if (productId) {
        byProduct[productId] = { base64: exportBase64, type: exportType };
      } else {
        byToken[id] = { base64: exportBase64, type: exportType };
      }

      return {
        ...state,
        byToken,
        byProduct,
        loading,
        errors,
        productId,
        productTitle,
        ...rest,
      };
    }

    case GET_PDF_BUNDLE_SUCCESS: {
      const {
        pdfsGroupedByProductId,
        productsBySalesChannel,
        loading,
        errors,
      } = action.payload;
      const downloads = pdfsGroupedByProductId.map(
        ({ productId, pdfTokens, ...rest }) => {
          const presentations =
            productsBySalesChannel.find(
              p => p.id.toString() === productId.toString(),
            )?.presentations || [];
          return {
            id: productId.toString(),
            tokens: pdfTokens,
            ...rest,
            ...getProductPresentations(presentations),
            errors,
          };
        },
      );

      return {
        ...state,
        downloads,
        loading,
      };
    }

    case GET_AUHTORIZATION_SCHEME_ERROR:
    case GET_PDF_BUNDLE_ERROR:
    case GET_GIFTCARD_ERROR: {
      const { error, loading } = action.payload;

      return { ...state, loading, error };
    }

    case SET_GIFTCARD_VERIFICATION: {
      const { name, value } = action.payload;

      return {
        ...state,
        verification: { ...state.verification, [name]: value },
        error: undefined,
      };
    }

    default: {
      return state;
    }
  }
}
