import getWin from './windowProvider';

const win = getWin();
const USER_STORAGE = 'userStorage';
const REDIRECT_PATH = 'redirectPath';
const VERIFICATION_PATH = 'giftcardId';
const PURCHASE_STATUS = 'purchase-status';
const PURCHASE_STATUS_OPT = {
  orderPurchased: 'order-purchased',
  defaultState: undefined,
};

const getUserStorage = (provider = 'localStorage') => {
  try {
    return JSON.parse(win[provider].getItem(USER_STORAGE)) || {};
  } catch (ignore) {
    return {};
  }
};

const setUserStorage = (userStorage, provider = 'localStorage') => {
  try {
    win[provider].setItem(USER_STORAGE, JSON.stringify(userStorage));
    // eslint-disable-next-line no-empty
  } catch (ignore) {}
};

const getDepartmentId = () => getUserStorage().departmentId;
const setDepartmentId = ({ departmentId }) => {
  setUserStorage({
    ...getUserStorage(),
    departmentId,
  });
};

const getHideRedeemOverviewNotice = () => {
  const userStorage = getUserStorage();
  const { hideRedeemOverviewNotice } = userStorage;
  return hideRedeemOverviewNotice;
};

const setHideRedeemOverviewNotice = hideRedeemOverviewNotice => {
  const userStorage = getUserStorage();
  setUserStorage({
    ...userStorage,
    hideRedeemOverviewNotice,
  });
};

const getHideCountryModal = () => {
  const userStorage = getUserStorage();
  const { hideCountryModal } = userStorage;
  return hideCountryModal;
};

const setHideCountryModal = hideCountryModal => {
  const userStorage = getUserStorage();
  setUserStorage({
    ...userStorage,
    hideCountryModal,
  });
};

const getDepartmentBasketId = ({ departmentId }) => {
  const userStorage = getUserStorage();
  const departmentBaskets = userStorage.departmentBasket;
  return departmentBaskets && departmentBaskets[departmentId];
};

const setDepartmentBasketId = ({ departmentId, basketId }) => {
  const userStorage = getUserStorage();
  const { departmentBasket } = userStorage;
  setUserStorage({
    ...userStorage,
    departmentBasket: {
      ...departmentBasket,
      [departmentId]: basketId,
    },
  });
};

const setSalesChannelId = ({ salesChannelId }) => {
  const userStorage = getUserStorage();
  setUserStorage({
    ...userStorage,
    salesChannelId,
  });
};

const removeSalesChannelId = () => {
  const { salesChannelId, ...rest } = getUserStorage();
  setUserStorage(rest);
  return salesChannelId;
};

// The sales channel id is kept in localStorage but was previously kept in sessionStorage.
// We read from both for a while in order to not mess up any ongoing orders.
// Reading from sessionStorage can safely be removed in the near future.
const getSalesChannelId = () => {
  const { salesChannelId: salesChannelIdInLocalStorage } = getUserStorage(
    'localStorage',
  );
  const { salesChannelId: salesChannelIdInSessionStorage } = getUserStorage(
    'sessionStorage',
  );
  return salesChannelIdInLocalStorage || salesChannelIdInSessionStorage;
};

const setSalesChannelBasketId = ({ salesChannelId, basketId, countryCode }) => {
  const userStorage = getUserStorage();
  const { salesChannelBaskets = [] } = userStorage;

  // this condition is used untill all the users localStorage is replaced with the new structure
  const usedSalesChannelBasket = !Array.isArray(salesChannelBaskets)
    ? []
    : salesChannelBaskets;

  const basketAlreadyExists = usedSalesChannelBasket.some(
    ({
      salesChannelId: existingSalesChannelId,
      countryCode: existingCountryCode,
    }) =>
      existingSalesChannelId === salesChannelId &&
      existingCountryCode === countryCode,
  );

  if (!basketAlreadyExists) {
    setUserStorage({
      ...userStorage,
      salesChannelBaskets: [
        {
          basketId,
          salesChannelId,
          countryCode,
        },
        ...usedSalesChannelBasket,
      ],
    });
  }
};

const getSalesChannelBasketId = ({ salesChannelId, countryCode }) => {
  const userStorage = getUserStorage();
  const { salesChannelBaskets = [] } = userStorage;

  let usedSalesChannelBasket = salesChannelBaskets;
  // this condition is used untill all the users localStorage is replaced with the new structure
  if (!Array.isArray(salesChannelBaskets)) {
    const basketId = salesChannelBaskets[salesChannelId];
    setSalesChannelBasketId({
      salesChannelId,
      basketId,
      countryCode,
    });

    const {
      salesChannelBaskets: updatedSalesChannelBaskets = [],
    } = getUserStorage();

    usedSalesChannelBasket = updatedSalesChannelBaskets;
  }

  const basket = usedSalesChannelBasket.find(
    ({
      salesChannelId: existingSalesChannelId,
      countryCode: existingCountryCode,
    }) =>
      existingSalesChannelId === salesChannelId &&
      existingCountryCode === countryCode,
  );

  return salesChannelBaskets && basket;
};

const removeBasketId = ({ basketId }) => {
  const userStorage = getUserStorage();
  const {
    salesChannelBaskets = [],
    departmentBasket = {},
    salesChannelId: currentSalesChannelId,
  } = userStorage;

  const salesChannelBasketsIsObject = !Array.isArray(salesChannelBaskets);
  // this condition is used untill all the users localStorage is replaced with the new structure

  const baskets = {
    salesChannelBaskets: salesChannelBasketsIsObject
      ? {
          ...salesChannelBaskets,
        }
      : [...salesChannelBaskets],
    departmentBasket: {
      ...departmentBasket,
    },
    salesChannelId: currentSalesChannelId,
  };
  // this condition is used untill all the users localStorage is replaced with the new structure
  if (salesChannelBasketsIsObject) {
    const salesChannelId = Object.keys(salesChannelBaskets).find(
      scId => salesChannelBaskets[scId] === basketId,
    );

    if (salesChannelId) {
      delete baskets.salesChannelBaskets[salesChannelId];
      if (currentSalesChannelId === salesChannelId) {
        removeSalesChannelId();
      }
    }
  } else {
    const { salesChannelId } =
      salesChannelBaskets.find(({ basketId: bId }) => bId === basketId) || {};

    const indexOfBasket = salesChannelBaskets.findIndex(
      ({ basketId: bId }) => bId === basketId,
    );

    if (indexOfBasket || indexOfBasket === 0) {
      baskets.salesChannelBaskets.splice(indexOfBasket, 1);

      if (currentSalesChannelId === salesChannelId) {
        removeSalesChannelId();
      }
    }
  }

  const departmentId = Object.keys(departmentBasket).find(
    dId => departmentBasket[dId] === basketId,
  );

  if (departmentId) {
    delete baskets.departmentBasket[departmentId];
  }

  setUserStorage({
    ...userStorage,
    ...baskets,
  });
};

const getRequestedPath = () => {
  try {
    const redirectPath = win.sessionStorage.getItem(REDIRECT_PATH);
    if (redirectPath) {
      win.sessionStorage.removeItem(REDIRECT_PATH);
      return redirectPath;
    }
    return null;
  } catch (ignore) {
    return null;
  }
};

const setRequestedPath = ({ pathname }) => {
  try {
    win.sessionStorage.setItem(REDIRECT_PATH, pathname);
    // eslint-disable-next-line no-empty
  } catch (ignore) {}
};

const getVerifyOwnerToken = () => {
  try {
    const verification = win.sessionStorage.getItem(VERIFICATION_PATH);
    if (verification) {
      win.sessionStorage.removeItem(VERIFICATION_PATH);
      return JSON.parse(verification);
    }
    return {};
  } catch (ignore) {
    return {};
  }
};

const setVerifyOwnerToken = ({ token, exportType, pin }) => {
  try {
    win.sessionStorage.setItem(
      VERIFICATION_PATH,
      JSON.stringify({ token, exportType, pin }),
    );
    // eslint-disable-next-line no-empty
  } catch (ignore) {}
};

const setOrderPurchaseStatus = status => {
  try {
    if (status) {
      win.sessionStorage.setItem(PURCHASE_STATUS, status);
    } else {
      win.sessionStorage.removeItem(PURCHASE_STATUS);
    }
    // eslint-disable-next-line no-empty
  } catch (ignore) {}
};

const getOrderPurchaseStatus = () => {
  try {
    return win.sessionStorage.getItem(PURCHASE_STATUS);
  } catch (ignore) {
    return undefined;
  }
};
const clearUserStorage = () => {
  return setUserStorage({});
};

export {
  getDepartmentId,
  setDepartmentId,
  getHideRedeemOverviewNotice,
  setHideRedeemOverviewNotice,
  getDepartmentBasketId,
  setDepartmentBasketId,
  getRequestedPath,
  setRequestedPath,
  getSalesChannelBasketId,
  setSalesChannelBasketId,
  getSalesChannelId,
  setSalesChannelId,
  removeSalesChannelId,
  removeBasketId,
  getVerifyOwnerToken,
  setVerifyOwnerToken,
  getOrderPurchaseStatus,
  setOrderPurchaseStatus,
  getHideCountryModal,
  setHideCountryModal,
  PURCHASE_STATUS_OPT,
  clearUserStorage,
};
