import queryString from 'query-string';
import {
  GET_SALES_CHANNEL_START,
  GET_SALES_CHANNEL_SUCCESS,
  GET_SALES_CHANNEL_ERROR,
  SET_SALES_CHANNEL_START,
  SET_SALES_CHANNEL_SUCCESS,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_COUNTRY_PRESENTATIONS_START,
  GET_COUNTRY_PRESENTATIONS_MESSAGE_START,
  GET_COUNTRY_PRESENTATIONS_SUCCESS,
  GET_COUNTRY_PRESENTATIONS_MESSAGE_SUCCESS,
  GET_COUNTRY_PRESENTATIONS_ERROR,
  GET_COUNTRY_PRESENTATIONS_MESSAGE_ERROR,
  GET_POPULAR_START,
  GET_POPULAR_SUCCESS,
  GET_POPULAR_ERROR,
  WELCOME_PATH,
  REDIRECT_START,
  SALES_CHANNELS,
  PRODUCT_TYPE_FILTERS,
  SALES_CHANNELS_GROUP,
  DEFAULT_LOCALE,
} from '../constants';
import history from '../history';
import { setRequestedPath, setSalesChannelId } from '../helpers/userStorage';
import queryCategories from './queries/categories.graphql';
import queryGetSalesChannelById from './queries/getSalesChannelById.graphql';
import globalizationConfig from './queries/globalizationConfig.graphql';
import queryGetSalesChannelBySlug from './queries/getSalesChannelBySlug.graphql';
import queryGetSalesFlows from './queries/salesFlow.graphql';
import queryGetCountryPresentations from './queries/countryPresentations.graphql';
import { getFromState, urlPrefix, getShopParams } from '../helpers';
import queryPopular from './queries/getPopular.graphql';

import { getPagePresentationKeys } from './page';
import { updateFiltersFromParams, getProducts } from './shop';

function getConfigFromState(state, name) {
  return (state && state.config && state.config[name]) || {};
}

// GraphQL cache should handle this ... ?!
function getSalesChannelFromState(state, id) {
  return (
    (state &&
      state.config &&
      state.config.salesChannels &&
      state.config.salesChannels[id]) ||
    {}
  );
}
const filterCategory = (category, countryCode) =>
  category.filter(
    element =>
      !element.availableInCountries ||
      element.availableInCountries.length === 0 ||
      element.availableInCountries.includes(countryCode),
  );

export function redirectTo({ pathname, push = false, unprefixed = false }) {
  return async (dispatch, getState) => {
    const {
      intl: { locale, countryCode, currency },
    } = getState();

    const path = unprefixed
      ? pathname
      : urlPrefix(pathname, { locale, countryCode, currency });

    dispatch({
      type: REDIRECT_START,
      payload: {
        redirecting: true,
        pathname: path,
      },
    });

    if (push) {
      history.push(path);
    } else {
      history.replace(path);
    }
  };
}

export function redirectToLogin({ pathname } = {}) {
  return async (dispatch, getState) => {
    const {
      intl: { locale, countryCode, currency },
    } = getState();

    const finalPathName =
      (pathname && urlPrefix(pathname, { locale, countryCode, currency })) ||
      history.location.pathname;

    dispatch({
      type: REDIRECT_START,
      payload: {
        redirecting: true,
        pathname: finalPathName,
      },
      meta: {
        type: 'login',
      },
    });

    setRequestedPath({
      pathname: finalPathName,
    });
    const path = urlPrefix(WELCOME_PATH, { locale, countryCode, currency });
    history.replace({ pathname: path });
  };
}

export function getSalesFlows({ salesChannelId } = {}) {
  return async (dispatch, getState, { client }) => {
    const {
      data: {
        salesFlow: {
          filter: { items },
        },
      },
    } = await client.query({
      query: queryGetSalesFlows,
      variables: {
        input: {
          salesChannelId,
          published: true,
          resolveUseSalesFlowFromOther: true,
        },
        pagination: {
          perPage: 5000,
        },
      },
      context: {
        fetchPolicy: 'cache-first',
      },
    });

    return items;
  };
}

export function getGlobalizationConfig() {
  return async (dispatch, getState, { client }) => {
    return client.query({
      query: globalizationConfig,
    });
  };
}
export function getSalesChannelById(id) {
  return async (dispatch, getState, { client }) => {
    const salesChannel = getSalesChannelFromState(getState(), id);
    if (salesChannel.id) {
      return salesChannel;
    }

    dispatch({
      type: GET_SALES_CHANNEL_START,
      meta: {
        id,
      },
    });

    try {
      const {
        data: { salesChannelActiveById },
      } = await client.query({
        query: queryGetSalesChannelById,
        variables: {
          id,
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      const salesFlows = await dispatch(getSalesFlows({ salesChannelId: id }));

      dispatch({
        type: GET_SALES_CHANNEL_SUCCESS,
        payload: {
          ...salesChannelActiveById,
          salesFlows,
        },
      });

      return getSalesChannelFromState(getState(), salesChannelActiveById.id);
    } catch (error) {
      dispatch({
        type: GET_SALES_CHANNEL_ERROR,
        payload: {
          error,
        },
      });
      return getSalesChannelFromState(getState(), id);
    }
  };
}

export function getSalesChannelBySlug(slug) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_SALES_CHANNEL_START,
      meta: {
        slug,
      },
    });

    try {
      const {
        data: { salesChannelActiveBySlug },
      } = await client.query({
        query: queryGetSalesChannelBySlug,
        variables: {
          slug,
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      const salesFlows = await dispatch(
        getSalesFlows({
          salesChannelId: salesChannelActiveBySlug.id,
        }),
      );

      dispatch({
        type: GET_SALES_CHANNEL_SUCCESS,
        payload: {
          ...salesChannelActiveBySlug,
          salesFlows,
        },
      });

      return getSalesChannelFromState(getState(), salesChannelActiveBySlug.id);
    } catch (error) {
      dispatch({
        type: GET_SALES_CHANNEL_ERROR,
        payload: {
          error,
        },
      });
      return undefined;
    }
  };
}

export function getCountryPresentations({ countryCode, locale }) {
  return async (dispatch, getState, { client }) => {
    const {
      config: { salesChannelId },
    } = getState();
    dispatch({
      type: GET_COUNTRY_PRESENTATIONS_START,
      meta: {
        countryCode,
      },
    });

    try {
      const {
        data: { countryPresentations },
        loading,
      } = await client.query({
        query: queryGetCountryPresentations,
        variables: {
          input: {
            countryCode: countryCode.toLowerCase(),
            expectedLanguage: locale || DEFAULT_LOCALE,
            inSalesChannel: salesChannelId,
          },
        },
        context: {
          service: 'cms',
          fetchPolicy: 'network-only',
        },
      });
      const {
        data: { countryPresentations: countryPresentationsAll },
      } = await client.query({
        query: queryGetCountryPresentations,
        variables: {
          input: {
            countryCode: countryCode.toLowerCase(),
            expectedLanguage: locale || DEFAULT_LOCALE,
          },
        },
        context: {
          service: 'cms',
          fetchPolicy: 'network-only',
        },
      });

      const countryPresentationsByDefault = countryPresentationsAll?.filter(
        presentation => presentation.inSalesChannel === null,
      );

      dispatch({
        type: GET_COUNTRY_PRESENTATIONS_SUCCESS,
        payload: {
          // merge countryPresentationsByDefault and countryPresentations to replace the default ones with sales channel specific presentations
          presentations: [
            ...countryPresentationsByDefault,
            ...countryPresentations,
          ],
          countryCode,
          locale,
          loading,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_COUNTRY_PRESENTATIONS_ERROR,
        payload: {
          loading: false,
          error,
        },
      });
    }
    return getConfigFromState(getState(), 'presentations');
  };
}

export function getCountryPresentationsForMessages({ countryCode, locale }) {
  return async (dispatch, getState, { client }) => {
    const {
      config: { salesChannelId },
    } = getState();

    dispatch({
      type: GET_COUNTRY_PRESENTATIONS_MESSAGE_START,
      meta: {
        countryCode,
      },
    });

    try {
      const {
        data: { countryPresentations },
        loading,
      } = await client.query({
        query: queryGetCountryPresentations,
        variables: {
          input: {
            countryCode: countryCode.toLowerCase(),
            expectedLanguage: locale || DEFAULT_LOCALE,
            inSalesChannel: salesChannelId,
          },
        },
        context: {
          service: 'cms',
          fetchPolicy: 'network-only',
        },
      });

      dispatch({
        type: GET_COUNTRY_PRESENTATIONS_MESSAGE_SUCCESS,
        payload: {
          presentations: countryPresentations,
          countryCode,
          locale,
          loading,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_COUNTRY_PRESENTATIONS_MESSAGE_ERROR,
        payload: {
          loading: false,
          error,
        },
      });
    }
    return getConfigFromState(getState(), 'message');
  };
}

export function getCategories() {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, countryCode },
      config: {
        salesChannelId,
        salesChannel: { group },
      },
    } = getState();

    dispatch({
      type: GET_CATEGORIES_START,
    });

    const isRedeem =
      salesChannelId === SALES_CHANNELS.REDEEM || group === 'Portal';

    try {
      const {
        data: {
          categoriesBySalesChannel: { recipient, regular, occasion },
          brandsBySalesChannel,
        },
        loading,
      } = await client.query({
        query: queryCategories,
        variables: {
          input: {
            salesChannelId,
            countryCode,
            expectedLanguage: locale,
          },
          id: salesChannelId,
          expectedLanguage: locale,
          countryCode,
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-and-network',
        },
      });

      const brand = brandsBySalesChannel.map(
        ({ id, name, availableInCountries, presentations }) => ({
          id,
          availableInCountries,
          label: name,
          presentations,
        }),
      );

      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: {
          data: {
            recipient: filterCategory(recipient, countryCode),
            brand: filterCategory(brand, countryCode),
            regular: filterCategory(regular, countryCode),
            occasion: filterCategory(occasion, countryCode),
            locale,
            type: isRedeem ? PRODUCT_TYPE_FILTERS : [],
          },
          loading,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORIES_ERROR,
        error,
        payload: {
          categories: {
            loading: false,
          },
        },
      });
    }
    return getConfigFromState(getState(), 'categories');
  };
}

export function getPopular() {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, currency, countryCode },
      config: { salesChannelId },
    } = getState();

    dispatch({
      type: GET_POPULAR_START,
      payload: {
        loading: true,
      },
      meta: {
        salesChannelId,
        locale,
        currency,
        countryCode,
      },
    });

    try {
      const {
        data: {
          categoriesBySalesChannel: { regular, occasion },
          popularProducts,
        },
        loading,
      } = await client.query({
        query: queryPopular,
        variables: {
          inputProducts: {
            salesChannelId,
            expectedLanguage: locale,
            expectedCurrency: currency,
            includeRedeemableCountries: [countryCode],
            includePresentationKeys: [
              'productTitle',
              'productLogo',
              'productLogoSquare',
              'productShortDescription',
            ],
          },
          inputCategories: {
            salesChannelId,
            expectedLanguage: locale,
            onlyIncludePopular: true,
          },
          inputPage: {
            salesChannelId,
            expectedLanguage: locale,
          },
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      dispatch({
        type: GET_POPULAR_SUCCESS,
        payload: {
          regular: filterCategory(regular, countryCode),
          occasion: filterCategory(occasion, countryCode),
          products: popularProducts,
          loading,
        },
      });
      return getFromState(getState(), 'home');
    } catch (error) {
      dispatch({
        type: GET_POPULAR_ERROR,
        error,
        payload: {
          loading: false,
        },
      });
      return getFromState(getState(), 'home');
    }
  };
}

export function setSalesChannel(salesChannelId) {
  return async (dispatch, getState) => {
    const {
      intl: { countryCode },
    } = getState();
    dispatch({
      type: SET_SALES_CHANNEL_START,
      meta: {
        salesChannelId,
        countryCode,
      },
    });

    const { group, id, ...rest } = await dispatch(
      getSalesChannelById(salesChannelId),
    );
    const {
      data: { globalization: { countries: globalcountries = [] } = {} } = {},
    } = await dispatch(getGlobalizationConfig());
    let presentations = {};
    if (id) {
      dispatch({
        type: SET_SALES_CHANNEL_SUCCESS,
        payload: {
          salesChannelId,
          countryCode,
          globalcountries,
        },
      });
      if (group === 'Portal') {
        const pageName = `portal_${id}`;
        const {
          byName: { [pageName]: p },
        } = await dispatch(getPagePresentationKeys({ pageName }));
        presentations = p;
      }

      const params = getShopParams(queryString.parse(history.location?.search));

      await dispatch(getCategories());
      await dispatch(getPopular());
      await dispatch(getProducts({ params }));
      await dispatch(updateFiltersFromParams(params));
      if (process.env.BROWSER) {
        setSalesChannelId({ salesChannelId });
      }
    }
    return {
      group,
      id,
      ...rest,
      presentations,
    };
  };
}

export function setCustomInitialProductFilters(defaultFilters, urlSlug) {
  return async (dispatch, getState) => {
    const {
      intl: { countryCode },
    } = getState();

    const { id, group, ...rest } = urlSlug
      ? await dispatch(getSalesChannelBySlug(urlSlug))
      : await dispatch(getSalesChannelById(SALES_CHANNELS.REDEEM));

    dispatch({
      type: SET_SALES_CHANNEL_START,
      meta: {
        salesChannelId: id,
        countryCode,
      },
    });

    const {
      data: { globalization: { countries: globalcountries = [] } = {} } = {},
    } = await dispatch(getGlobalizationConfig());

    let presentations = {};
    if (id) {
      dispatch({
        type: SET_SALES_CHANNEL_SUCCESS,
        payload: {
          salesChannelId: id,
          countryCode,
          globalcountries,
        },
      });

      if (group === SALES_CHANNELS_GROUP.PORTAL) {
        const pageName = `portal_${id}`;
        const {
          byName: { [pageName]: portalPresentation },
        } = await dispatch(getPagePresentationKeys({ pageName }));
        presentations = portalPresentation;
      }

      await dispatch(getProducts({ params: defaultFilters }));

      if (process.env.BROWSER) {
        setSalesChannelId({ salesChannelId: id });
      }
    }
    return {
      id,
      group,
      ...rest,
      presentations,
    };
  };
}
