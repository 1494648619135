/* eslint-disable css-modules/no-unused-class */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Icon from '../Icon';
import s from './Clickable.scss';

class Clickable extends PureComponent {
  static propTypes = {
    tag: PropTypes.string,
    label: PropTypes.node,
    children: PropTypes.node,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    type: PropTypes.string,
    primary: PropTypes.bool,
    basic: PropTypes.bool,
    selected: PropTypes.bool,
    tertiary: PropTypes.bool,
    naked: PropTypes.bool,
    disabled: PropTypes.bool,
    rtl: PropTypes.bool,
    link: PropTypes.node,
    size: PropTypes.oneOf(['slim', 'large']),
    fluid: PropTypes.bool,
    flat: PropTypes.bool,
    inline: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.oneOf([false, undefined]),
    ]),
  };

  static defaultProps = {
    label: undefined,
    children: undefined,
    tag: 'button',
    type: 'button',
    primary: false,
    basic: false,
    selected: false,
    tertiary: false,
    naked: false,
    icon: undefined,
    rtl: false,
    disabled: false,
    link: undefined,
    size: undefined,
    fluid: false,
    flat: false,
    inline: false,
    className: undefined,
    onClick: undefined,
  };

  render() {
    const {
      tag: Tag,
      children,
      label,
      primary,
      basic,
      selected,
      tertiary,
      naked,
      icon,
      rtl,
      disabled,
      link,
      inline,
      type,
      size,
      fluid,
      flat,
      className,
      onClick,
      ...props
    } = this.props;
    const classes = cx(
      s.root,
      fluid && s.fluid,
      primary && s.primary,
      basic && s.basic,
      tertiary && s.tertiary,
      naked && s.naked,
      flat && s.flat,
      disabled && s.disabled,
      selected && s.selected,
      inline && s.inline,
      size && s[`${size}`],
      icon && s.hasIcon,
      rtl && s.rtl,
      Tag !== 'button' && disabled && s.disabled,
      !children && !label && s.onlyIcon,
      className && className,
    );

    if (link) {
      return (
        <link.type
          className={classes}
          {...link.props}
          {...props}
          disabled={disabled}
        >
          {icon && <Icon {...icon} className={s.icon} />}
          {label && label}
          {children && children}
        </link.type>
      );
    }

    return (
      <Tag
        className={classes}
        disabled={disabled}
        type={type}
        onClick={!disabled ? onClick : undefined}
        {...props}
      >
        {icon && <Icon {...icon} className={s.icon} />}
        {label && label}
        {children && children}
      </Tag>
    );
  }
}

export default withStyles(s)(Clickable);
