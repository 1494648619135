import {
  GET_SALES_CHANNEL_START,
  GET_SALES_CHANNEL_SUCCESS,
  GET_SALES_CHANNEL_ERROR,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  SET_SALES_CHANNEL_SUCCESS,
  SHOP_PATH,
  OCCASIONS_PATH,
  GET_COUNTRY_PRESENTATIONS_START,
  GET_COUNTRY_PRESENTATIONS_ERROR,
  GET_COUNTRY_PRESENTATIONS_SUCCESS,
  SALES_CHANNELS_GROUP,
} from '../constants';
import { getCategoryPresentations, sortBy } from '../helpers';

export default function config(state = {}, action) {
  switch (action.type) {
    case GET_SALES_CHANNEL_START: {
      return {
        ...state,
      };
    }

    case GET_SALES_CHANNEL_SUCCESS: {
      const {
        id,
        group,
        settings,
        urlSlug,
        salesFlows,
        allowsWhiteLabeling,
      } = action.payload;
      const mappedSalesFlows = salesFlows.reduce(
        (
          flows,
          {
            countryCode,
            defaultCurrency,
            defaultLanguage,
            supportedCurrencies,
            supportedLanguages,
            displayCurrency,
            published,
          },
        ) => ({
          ...flows,
          [countryCode]: {
            published,
            label: countryCode,
            value: countryCode,
            displayCurrency: displayCurrency.toLowerCase(),
            defaultCurrency: defaultCurrency.toLowerCase(),
            defaultLocale: defaultLanguage.toLowerCase(),
            supportedCurrencies: supportedCurrencies.map(s => ({
              label: s,
              value: s,
            })),
            supportedLanguages: supportedLanguages.map(s => ({
              label: s.toUpperCase(),
              value: s.toLowerCase(),
            })),
          },
        }),
        {},
      );
      // set if portal allowed to white label logo
      const showWhiteLabelLogoUrl =
        group === SALES_CHANNELS_GROUP.PORTAL &&
        allowsWhiteLabeling &&
        settings?.logoUrl.length > 0;

      return {
        ...state,
        salesChannels: {
          ...state.salesChannels,
          [id]: {
            id,
            group,
            slug: urlSlug,
            logoUrl: settings && settings.logoUrl,
            bannerUrl: settings && settings.bannerUrl,
            colorScheme: settings && settings.colorScheme,
            salesFlows: mappedSalesFlows || {},
            showWhiteLabelLogoUrl: showWhiteLabelLogoUrl || null,
          },
        },
      };
    }

    case GET_SALES_CHANNEL_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_SALES_CHANNEL_SUCCESS: {
      const {
        salesChannelId,
        countryCode,
        globalcountries = [],
        countries = [],
      } = action.payload;
      const salesChannel = state.salesChannels[salesChannelId];
      const countrySalesFlow = salesChannel.salesFlows[countryCode] || {};
      const getCountriesFromGlobalizationData = () =>
        Object.keys(salesChannel.salesFlows)
          .reduce((retValue, key) => {
            const published = salesChannel?.salesFlows?.[key]?.published;
            if (!published) {
              return retValue;
            }
            const { countryName: label, codeAlpha2: value } =
              globalcountries.find(current => current.codeAlpha2 === key) || {};

            retValue.push({
              label,
              value,
            });
            return retValue;
          }, [])
          .sort(sortBy('label'));
      return {
        ...state,
        salesChannelId,
        salesChannel,
        currencies: countrySalesFlow.supportedCurrencies
          ? countrySalesFlow.supportedCurrencies.sort(sortBy('label'))
          : [],
        languages: countrySalesFlow.supportedLanguages || [],
        countries: countries?.length
          ? countries
          : getCountriesFromGlobalizationData(),
      };
    }

    case GET_COUNTRY_PRESENTATIONS_START:
    case GET_CATEGORIES_START: {
      return {
        ...state,
      };
    }

    case GET_COUNTRY_PRESENTATIONS_SUCCESS: {
      const { presentations, countryCode, locale, loading } = action.payload;
      const mappedPresentations = presentations.reduce(
        (acc, { presentationKey, textContent, image, languageCode }) => {
          if (!acc[presentationKey] || languageCode.toLowerCase() === locale) {
            acc[presentationKey] = image || textContent;
          }
          return acc;
        },
        {},
      );
      return {
        ...state,
        presentations: {
          ...state.presentations,
          [countryCode]: mappedPresentations,
          originalPresentations: { ...presentations },
        },
        loading,
      };
    }

    case GET_CATEGORIES_SUCCESS: {
      const {
        data: { regular, occasion, brand, locale },
        loading,
      } = action.payload;

      const occasionCategory = Object.assign(
        {},
        ...occasion.map(({ id, presentations }) => {
          const pres = getCategoryPresentations(presentations);

          return {
            [id]: {
              ...pres,
              id,
              label: pres.categoryTranslatedName || `Missing name (${locale})`,
              prioritisedMenuItem:
                Number(pres?.prioritisedMenuItem) || undefined,
            },
          };
        }),
      );

      const menuCategories = regular.map(({ id, presentations }) => {
        const {
          categoryTranslatedName,
          prioritisedMenuItem,
        } = getCategoryPresentations(presentations);

        return {
          id,
          to: `/shop?category=${id}`,
          label: categoryTranslatedName || `Missing name (${locale})`,
          prioritisedMenuItem: Number(prioritisedMenuItem) || undefined,
        };
      });

      const menuOccasions = Object.values(occasionCategory).map(
        ({ id, categorySlug, label, prioritisedMenuItem }) => ({
          id,
          to: `${OCCASIONS_PATH}/${categorySlug}/${id}`,
          label,
          prioritisedMenuItem,
        }),
      );

      const menuBrands = brand.map(({ id, label, presentations }) => {
        const {
          prioritisedMenuItem,
          brandMetaTitle,
          brandMetaDescription,
        } = getCategoryPresentations(presentations);

        return {
          id,
          to: `${SHOP_PATH}?brand=${id}`,
          label,
          prioritisedMenuItem: Number(prioritisedMenuItem) || undefined,
          brandMetaTitle,
          brandMetaDescription,
        };
      });

      const menu = Object.assign({
        categories: menuCategories,
        occasions: menuOccasions,
        brands: menuBrands,
      });

      return {
        ...state,
        menu,
        occasions: occasionCategory,
        loading,
      };
    }

    case GET_COUNTRY_PRESENTATIONS_ERROR:
    case GET_CATEGORIES_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
