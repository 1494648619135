/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Label from '../Label';
import { notificationMessages as messages } from '../../defineMessages';
import List from '../List';

export const addProductStart = () => (
  <Label bold>
    <FormattedMessage {...messages.addStart} />
  </Label>
);

export const addProductSuccess = () => (
  <Label bold>
    <FormattedMessage {...messages.addSuccess} />
  </Label>
);

export const postcodeError = errorMessage => <Label bold>{errorMessage}</Label>;

export const accountUpdateStart = () => (
  <Label bold>
    <FormattedMessage {...messages.accountUpdateStart} />
  </Label>
);

export const accountUpdateSuccess = () => (
  <Label bold>
    <FormattedMessage {...messages.accountUpdateSuccess} />
  </Label>
);

export const accountCreateSuccess = () => (
  <Label bold>
    <FormattedMessage {...messages.accountCreateSuccess} />
  </Label>
);

export const showUnexpectedError = () => (
  <Label bold>
    <FormattedMessage {...messages.unexpectedError} />
  </Label>
);

export const showError = ({
  errors,
  translated = false,
  context,
  autoClose,
}) => {
  if (!errors.length) {
    return;
  }
  const chooseMessage = ({ code, message }) => {
    const msg = context ? messages[`${context}${code}`] : messages[code];
    if (msg) {
      return <FormattedMessage {...msg} />;
    }
    return !translated ? message || code : '';
  };

  toast.error(
    <List container direction="column">
      {errors.map(({ code, message, path }) => (
        <List key={`${path ? path.join('.') : code}`} item component="li">
          <Label bold>{chooseMessage({ code, message })}</Label>
        </List>
      ))}
    </List>,
    {
      autoClose: autoClose || false,
    },
  );
};

export const resendOrderConfirmationStart = () => (
  <Label bold>
    <FormattedMessage {...messages.resendOrderConfirmationStart} />
  </Label>
);

export const resendOrderConfirmationSuccess = () => (
  <Label bold>
    <FormattedMessage {...messages.resendOrderConfirmationSuccess} />
  </Label>
);

export const resendOrderConfirmationError = () => (
  <Label bold>
    <FormattedMessage {...messages.resendOrderConfirmationError} />
  </Label>
);
