export default function createHelpers({
  apolloClient,
  fetch,
  history,
  config,
}) {
  return {
    client: apolloClient,
    history,
    fetch,
    config,
  };
}
