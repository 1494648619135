// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "._3aFz2 {\n  margin-top: 0;\n  margin-bottom: 0;\n  color: inherit;\n  font-size: inherit;\n  font-weight: normal;\n}\n\n._1ew3H {\n  color: var(--body-subdued-color);\n}\n\n._2ryHI {\n  color: var(--highlight-color);\n}\n\n.T_KXX {\n  text-transform: uppercase;\n}\n\n.Tl1Xx {\n  font-size: var(--font-size-xl);\n  font-weight: 600;\n}\n\n._2VqCd {\n  font-size: var(--font-size-lg);\n  font-weight: 600;\n}\n\n._1vY4g {\n  font-size: var(--font-size-md);\n  font-weight: 400;\n}\n\n._2pxdm {\n  font-size: var(--font-size-sm);\n  font-weight: 400;\n}\n\n._1sq6B {\n  font-size: var(--font-size-xs);\n}\n\n._2o4Wp {\n  font-size: var(--font-size-heading);\n  font-weight: 600;\n}\n\n.Yw2-N {\n  font-size: var(--font-size-subheading);\n  font-weight: 600;\n}\n\n._2cuI6 {\n  font-size: var(--font-size-caption);\n  font-weight: 400;\n}\n\n.DVxdA {\n  font-size: var(--font-size-body);\n}\n\n._2s58G {\n  display: flex;\n  align-items: center;\n}\n\n._2s58G .rTkN6 {\n  flex: 0 0 auto;\n  margin-right: 8px;\n}\n\n._2s58G ._1ymd5 {\n  padding-right: 16px;\n  padding-left: 24px;\n}\n\n._2s58G ._1ymd5 .rTkN6 {\n  margin-left: 8px;\n  margin-right: auto;\n}\n\n._20IPv {\n  font-weight: 600;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_3aFz2",
	"subdued": "_1ew3H",
	"highlight": "_2ryHI",
	"uppercase": "T_KXX",
	"xl": "Tl1Xx",
	"lg": "_2VqCd",
	"md": "_1vY4g",
	"sm": "_2pxdm",
	"xs": "_1sq6B",
	"heading": "_2o4Wp",
	"subheading": "Yw2-N",
	"caption": "_2cuI6",
	"body": "DVxdA",
	"hasIcon": "_2s58G",
	"icon": "rTkN6",
	"rtl": "_1ymd5",
	"bold": "_20IPv"
};
module.exports = exports;
