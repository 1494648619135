// eslint-disable-next-line import/prefer-default-export
export const isToday = date => {
  const today = new Date();
  const dateToCheck = typeof date === 'string' ? new Date(date) : date;
  return (
    dateToCheck.getDate() === today.getDate() &&
    dateToCheck.getMonth() === today.getMonth() &&
    dateToCheck.getFullYear() === today.getFullYear()
  );
};
