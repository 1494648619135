import {
  GET_WRAPPINGS_START,
  GET_WRAPPINGS_ERROR,
  GET_WRAPPINGS_SUCCESS,
} from '../constants';

export default function wrapping(state = {}, action) {
  switch (action.type) {
    case GET_WRAPPINGS_START: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case GET_WRAPPINGS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_WRAPPINGS_ERROR: {
      const { loading, errors } = action.payload;
      return {
        ...state,
        errors,
        loading,
      };
    }
    default: {
      return state;
    }
  }
}
