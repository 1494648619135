/* eslint-disable import/prefer-default-export */

import { TOGGLE_MOBILE_MENU, TOGGLE_HEADER } from '../constants';

export function toggleMobileMenu(menu) {
  return {
    type: TOGGLE_MOBILE_MENU,
    payload: menu,
  };
}

export function toggleHeader(top) {
  return {
    type: TOGGLE_HEADER,
    payload: top,
  };
}
