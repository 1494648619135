// Redux actions

export const SET_FIELD = 'SET_FIELD';
export const GET_SALES_CHANNEL_START = 'GET_SALES_CHANNEL_START';
export const GET_SALES_CHANNEL_SUCCESS = 'GET_SALES_CHANNEL_SUCCESS';
export const GET_SALES_CHANNEL_ERROR = 'GET_SALES_CHANNEL_ERROR';
export const SET_SALES_CHANNEL_START = 'SET_SALES_CHANNEL_START';
export const SET_SALES_CHANNEL_SUCCESS = 'SET_SALES_CHANNEL_SUCCESS';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_CURRENCY = 'SET_CURRENCY';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const TOGGLE_GLOBALIZATION_MENU = 'TOGGLE_GLOBALIZATION_MENU';
export const GET_COUNTRY_START = 'GET_COUNTRY_START';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';
export const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_COUNTRY_PRESENTATIONS_START =
  'GET_COUNTRY_PRESENTATIONS_START';
export const GET_COUNTRY_PRESENTATIONS_SUCCESS =
  'GET_COUNTRY_PRESENTATIONS_SUCCESS';
export const GET_COUNTRY_PRESENTATIONS_ERROR =
  'GET_COUNTRY_PRESENTATIONS_ERROR';
export const GET_COUNTRY_PRESENTATIONS_MESSAGE_START =
  'GET_COUNTRY_PRESENTATIONS_MESSAGE_START';
export const GET_COUNTRY_PRESENTATIONS_MESSAGE_SUCCESS =
  'GET_COUNTRY_PRESENTATIONS_MESSAGE_SUCCESS';
export const GET_COUNTRY_PRESENTATIONS_MESSAGE_ERROR =
  'GET_COUNTRY_PRESENTATIONS_MESSAGE_ERROR';
export const GET_PAGE_START = 'GET_PAGE_START';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_SINGLE_FILTER = 'UPDATE_SINGLE_FILTER';
export const UPDATE_PRICE_INTERVAL = 'UPDATE_PRICE_INTERVAL';

export const CLOSE_SERVICE_MESSAGE = 'CLOSE_SERVICE_MESSAGE';

// Products
export const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_PRODUCT_PRICE_INTERVAL_START =
  'GET_PRODUCT_PRICE_INTERVAL_START';
export const GET_PRODUCT_PRICE_INTERVAL_SUCCESS =
  'GET_PRODUCT_PRICE_INTERVAL_SUCCESS';
export const GET_PRODUCT_PRICE_INTERVAL_ERROR =
  'GET_PRODUCT_PRICE_INTERVAL_ERROR';
export const SORT_PRODUCTS_BY = 'SORT_PRODUCTS_BY';

export const GET_PRODUCT_SINGLE_START = 'GET_PRODUCT_SINGLE_START';
export const GET_PRODUCT_SINGLE_SUCCESS = 'GET_PRODUCT_SINGLE_SUCCESS';
export const GET_PRODUCT_SINGLE_ERROR = 'GET_PRODUCT_SINGLE_ERROR';
export const GET_PRODUCT_STOCK_INFO_START = 'GET_PRODUCT_STOCK_INFO_START';
export const GET_PRODUCT_STOCK_INFO_SUCCESS = 'GET_PRODUCT_STOCK_INFO_SUCCESS';
export const GET_PRODUCT_STOCK_INFO_ERROR = 'GET_PRODUCT_STOCK_INFO_ERROR';
export const GET_POPULAR_START = 'GET_POPULAR_START';
export const GET_POPULAR_SUCCESS = 'GET_POPULAR_SUCCESS';
export const GET_POPULAR_ERROR = 'GET_POPULAR_ERROR';
export const SET_DELIVERY_METHOD = 'SET_DELIVERY_METHOD';
export const SET_CURRENCY_FILTER = 'SET_CURRENCY_FILTER';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';

// Greetings

export const SET_GREETING = 'SET_GREETING';

export const GET_GREETING_START = 'GET_GREETING_START';
export const GET_GREETING_ERROR = 'GET_GREETING_ERROR';
export const GET_GREETING_SUCCESS = 'GET_GREETING_SUCCESS';
export const UPDATE_GREETING_START = 'UPDATE_GREETING_START';
export const UPDATE_GREETING_ERROR = 'UPDATE_GREETING_ERROR';
export const UPDATE_GREETING_SUCCESS = 'UPDATE_GREETING_SUCCESS';

// Wrappings

export const GET_WRAPPINGS_START = 'GET_WRAPPINGS_START';
export const GET_WRAPPINGS_ERROR = 'GET_WRAPPINGS_ERROR';
export const GET_WRAPPINGS_SUCCESS = 'GET_WRAPPINGS_SUCCESS';
export const SET_WRAPPING = 'SET_WRAPPING';

// Basket
export const CREATE_BASKET_START = 'CREATE_BASKET_START';
export const CREATE_BASKET_SUCCESS = 'CREATE_BASKET_SUCCESS';
export const CREATE_BASKET_ERROR = 'CREATE_BASKET_ERROR';
export const GET_BASKET_START = 'GET_BASKET_START';
export const GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS';
export const GET_BASKET_ERROR = 'GET_BASKET_ERROR';
export const UPDATE_BASKET_START = 'UPDATE_BASKET_START';
export const UPDATE_BASKET_SUCCESS = 'UPDATE_BASKET_SUCCESS';
export const UPDATE_BASKET_ERROR = 'UPDATE_BASKET_ERROR';
export const FINALIZE_BASKET_START = 'FINALIZE_BASKET_START';
export const FINALIZE_BASKET_SUCCESS = 'FINALIZE_BASKET_SUCCESS';
export const FINALIZE_BASKET_ERROR = 'FINALIZE_BASKET_ERROR';
export const ADD_REDEEM_START = 'ADD_REDEEM_START';
export const ADD_REDEEM_SUCCESS = 'ADD_REDEEM_SUCCESS';
export const ADD_REDEEM_ERROR = 'ADD_REDEEM_ERROR';
export const REMOVE_REDEEM_START = 'REMOVE_REDEEM_START';
export const REMOVE_REDEEM_SUCCESS = 'REMOVE_REDEEM_SUCCESS';
export const REMOVE_REDEEM_ERROR = 'REMOVE_REDEEM_ERROR';
export const ADD_PRODUCT_START = 'ADD_PRODUCT_START';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';
export const GET_SHIPPINGQUOTE_START = 'GET_SHIPPINGQUOTE_START';
export const GET_SHIPPINGQUOTE_SUCCESS = 'GET_SHIPPINGQUOTE_SUCCESS';
export const GET_SHIPPINGQUOTE_ERROR = 'GET_SHIPPINGQUOTE_ERROR';
export const SET_SHIPPINGQUOTE_START = 'SET_SHIPPINGQUOTE_START';
export const SET_SHIPPINGQUOTE_SUCCESS = 'SET_SHIPPINGQUOTE_SUCCESS';
export const SET_SHIPPINGQUOTE_ERROR = 'SET_SHIPPINGQUOTE_ERROR';

// Loggedin User
export const GET_LOGGEDIN_USER_START = 'GET_LOGGEDIN_USER_START';
export const GET_LOGGEDIN_USER_SUCCESS = 'GET_LOGGEDIN_USER_SUCCESS';
export const GET_LOGGEDIN_USER_ERROR = 'GET_LOGGEDIN_USER_ERROR';

export const SET_LOGGED_OUT = 'SET_LOGGED_OUT';

// Occasion
export const GET_OCCASION_START = 'GET_OCCASION_START';
export const GET_OCCASION_SUCCESS = 'GET_OCCASION_SUCCESS';
export const GET_OCCASION_ERROR = 'GET_OCCASION_ERROR';

// Order
export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
export const ORDER_REFERENCE = 'ORDER_REFERENCE';

// Account
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';
export const GET_ACCOUNT_START = 'GET_ACCOUNT_START';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
export const GET_ORDERS_START = 'GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_DOWNLOAD_START = 'GET_ORDERS_DOWNLOAD_START';
export const GET_ORDERS_DOWNLOAD_SUCCESS = 'GET_ORDERS_DOWNLOAD_SUCCESS';
export const GET_ORDERS_DOWNLOAD_ERROR = 'GET_ORDERS_DOWNLOAD_ERROR';
export const GET_ORDERS_DOWNLOAD_STATE_START =
  'GET_ORDERS_DOWNLOAD_STATE_START';
export const GET_ORDERS_DOWNLOAD_STATE_SUCCESS =
  'GET_ORDERS_DOWNLOAD_STATE_SUCCESS';
export const GET_ORDERS_DOWNLOAD_STATE_ERROR =
  'GET_ORDERS_DOWNLOAD_STATE_ERROR';
export const GET_ORDERS_DOWNLOAD_LINK_START = 'GET_ORDERS_DOWNLOAD_LINK_START';
export const GET_ORDERS_DOWNLOAD_LINK_SUCCESS =
  'GET_ORDERS_DOWNLOAD_LINK_SUCCESS';
export const GET_ORDERS_DOWNLOAD_LINK_ERROR = 'GET_ORDERS_DOWNLOAD_LINK_ERROR';
export const GET_MY_ORDER_SUCCESS = 'GET_MY_ORDER_SUCCESS';
export const GET_MY_ORDER_ERROR = 'GET_MY_ORDER_ERROR';
export const GET_MY_ORDER_START = 'GET_MY_ORDER_START';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT_START = 'UPDATE_ACCOUNT_START';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const TOGGLE_DELIVERY_ADDRESS = 'TOGGLE_DELIVERY_ADDRESS';
export const SET_DEPARTMENT = 'SET_DEPARTMENT';
export const RESET_DEPARTMENT_FORM = 'RESET_DEPARTMENT_FORM';
export const SET_ACCOUNT_FIELD = 'SET_ACCOUNT_FIELD';
export const SET_DEPARTMENT_ADDRESS_FIELD = 'SET_DEPARTMENT_ADDRESS_FIELD';
export const SET_DEPARTMENT_DELIVERY_ADDRESS_FIELD =
  'SET_DEPARTMENT_DELIVERY_ADDRESS_FIELD';
export const SET_DELIVERY_ADDRESS_FIELD = 'SET_DELIVERY_ADDRESS_FIELD';
export const DOWNLOAD_REQUEST_STATUS = {
  CREATED: 'Created',
  PENDING: 'Pending',
};

// Giftcard
export const GET_AUHTORIZATION_SCHEME_START = 'GET_AUHTORIZATION_SCHEME_START';
export const GET_AUHTORIZATION_SCHEME_ERROR = 'GET_AUHTORIZATION_SCHEME_ERROR';
export const GET_AUHTORIZATION_SCHEME_SUCCESS =
  'GET_AUHTORIZATION_SCHEME_SUCCESS';
export const GET_GIFTCARD_START = 'GET_GIFTCARD_START';
export const GET_GIFTCARD_ERROR = 'GET_GIFTCARD_ERROR';
export const GET_GIFTCARD_SUCCESS = 'GET_GIFTCARD_SUCCESS';
export const SET_GIFTCARD_VERIFICATION = 'SET_GIFTCARD_VERIFICATION';

export const GET_PDF_BUNDLE_START = 'GET_PDF_BUNDLE_START';
export const GET_PDF_BUNDLE_SUCCESS = 'GET_PDF_BUNDLE_SUCCESS';
export const GET_PDF_BUNDLE_ERROR = 'GET_PDF_BUNDLE_ERROR';

// Loading

export const REDIRECT_START = 'REDIRECT_START';
export const PAGE_LOAD = 'PAGE_LOAD';
export const BASKET_ERROR_TOASTIFY = 'BASKET_ERROR_TOASTIFY';
