const getFieldName = path => {
  if (path && path.length) {
    // path structure [namespace, mutation/ query, path, to, field]
    if (path.length > 2) {
      return path.slice(2).join('.');
    }
    // path missing namespace, workaround for createDepartment mutation
    // (enabling for all would convert all errors to fieldErrors)
    if (path.length === 2 && path[1] === 'companyRegistrationId') {
      return path[1];
    }
  }
  return null;
};

const errorReducer = (state, action) => {
  const { error } = action;
  // Errors go away upon next dispatch

  if (error) {
    if (typeof error === 'string') {
      return {
        ...state,
        errors: [
          {
            code: error,
            message: error,
          },
        ],
      };
    }
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors && graphQLErrors.length) {
      return graphQLErrors.reduce(
        (acc, { extensions: { code = 'UNKNOWN' } = {}, path, message }) => {
          const e = {
            code,
            path,
            field: getFieldName(path),
            message,
          };
          // Backend workaround
          if (e.code !== 'ServiceException') {
            if (e.field) {
              acc.fieldErrors[e.field] = e;
            } else acc.errors.push(e);
          }
          return acc;
        },
        {
          errors: [],
          fieldErrors: {},
          networkError,
        },
      );
    }
  }

  return {
    ...state,
    errors: [],
    fieldErrors: [],
    networkError: undefined,
  };
};

export default errorReducer;
