import {
  GET_LOGGEDIN_USER_START,
  GET_LOGGEDIN_USER_SUCCESS,
  GET_LOGGEDIN_USER_ERROR,
  SET_LOGGED_OUT,
} from '../constants';

export default function config(state = null, action) {
  switch (action.type) {
    case GET_LOGGEDIN_USER_START:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGGEDIN_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_LOGGEDIN_USER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case SET_LOGGED_OUT: {
      return {
        loading: false,
        error: false,
      };
    }
    default: {
      return state;
    }
  }
}
