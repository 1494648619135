import {
  GET_POPULAR_ERROR,
  GET_POPULAR_START,
  GET_POPULAR_SUCCESS,
} from '../constants';
import { getCategoryPresentations, getProductPresentations } from '../helpers';

export default function home(state = {}, action) {
  switch (action.type) {
    case GET_POPULAR_START: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_POPULAR_SUCCESS: {
      const { products, regular, occasion, loading } = action.payload;
      const popularProducts = products.map(
        ({ id, minPrice, presentations, productType }) => ({
          id,
          productType,
          price: minPrice,
          ...getProductPresentations(presentations),
        }),
      );
      const popularCategories = regular.map(({ id, presentations }) => ({
        id,
        ...getCategoryPresentations(presentations),
      }));
      const popularOccasions = occasion.map(({ id, presentations }) => ({
        id,
        ...getCategoryPresentations(presentations),
      }));
      return {
        ...state,
        popularProducts,
        popularCategories,
        popularOccasions,
        loading,
      };
    }

    case GET_POPULAR_ERROR: {
      return {
        ...state,
        error: { ...action.payload.error, ...state.error },
        loading: action.payload.loading,
      };
    }

    default: {
      return state;
    }
  }
}
