/* eslint-disable css-modules/no-unused-class */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import ReactModal from 'react-modal';
import cx from 'classnames';
import s from './Modal.scss';
import Clickable from '../Clickable';
import { ICONS } from '../../constants';
import Label from '../Label';
import List from '../List';
import Grid from '../Grid';

// https://github.com/reactjs/react-modal/issues/632
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#app');
}

class Modal extends Component {
  static propTypes = {
    title: PropTypes.node,
    children: PropTypes.node.isRequired,
    triggerProps: PropTypes.shape(),
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    size: PropTypes.string,
    fullScreen: PropTypes.bool,
    animation: PropTypes.oneOf([
      'fadeIn',
      'slideLeft',
      'slideUp',
      'slideDown',
      'slideRight',
    ]),
    maxWidth: PropTypes.bool,
    isVisible: PropTypes.bool,
    header: PropTypes.bool,
    headerAction: PropTypes.node,
    headerInput: PropTypes.node,
    footer: PropTypes.node,
    handleClick: PropTypes.func,
    onClose: PropTypes.func,
    divider: PropTypes.bool,
  };

  static defaultProps = {
    title: null,
    triggerProps: {
      label: 'Open Modal',
      basic: true,
    },
    size: undefined,
    animation: 'fadeIn',
    fullScreen: false,
    maxWidth: false,
    isVisible: undefined,
    className: undefined,
    headerClassName: undefined,
    header: true,
    headerAction: null,
    headerInput: undefined,
    footer: undefined,
    handleClick: null,
    onClose: null,
    divider: false,
  };

  state = {
    showModal: false,
  };

  handleOpenModal = () => {
    if (this.props.handleClick) {
      this.props.handleClick();
    } else {
      this.setState({ showModal: true });
    }
  };

  handleCloseModal = () => {
    if (this.props.handleClick) {
      this.props.handleClick();
    } else {
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.setState({ showModal: false });
    }
  };

  render() {
    const {
      size,
      isVisible,
      children,
      title,
      triggerProps,
      animation,
      fullScreen,
      maxWidth,
      header,
      footer,
      headerAction,
      headerInput,
      className,
      headerClassName,
      divider,
    } = this.props;
    const { showModal } = this.state;
    const baseClassName = cx(
      s.root,
      className && className,
      fullScreen && s.fullScreen,
      maxWidth && s.maxWidth,
      size && s[size],
      s[animation],
    );
    const headerClasses = cx(s.header, headerClassName && headerClassName);
    return (
      <Fragment>
        <Clickable onClick={this.handleOpenModal} {...triggerProps} />
        <ReactModal
          isOpen={typeof isVisible !== 'undefined' ? isVisible : showModal}
          contentLabel={title}
          onRequestClose={this.handleCloseModal}
          className={{
            base: baseClassName,
            afterOpen: s[`${animation}--afterOpen`],
            beforeClose: s[`${animation}--beforeClose`],
          }}
          overlayClassName={s.overlay}
          bodyOpenClassName={s.open}
          closeTimeoutMS={300}
        >
          {header && (
            <Grid component="header" className={headerClasses}>
              {headerInput ? (
                <Grid item className={s.headerInput}>
                  {headerInput}
                </Grid>
              ) : (
                <List container component="div" spacing={8} justify="center">
                  {title && (
                    <List item component="div">
                      <Label bold tag="h2" label={title} />
                    </List>
                  )}

                  {headerAction && (
                    <Fragment>
                      {divider && (
                        <List
                          item
                          component="span"
                          style={{ color: '#919eab' }}
                        >
                          |
                        </List>
                      )}
                      <List item component="div">
                        {headerAction}
                      </List>
                    </Fragment>
                  )}
                </List>
              )}
              <Clickable
                basic
                onClick={this.handleCloseModal}
                icon={{
                  name: ICONS.CLOSE,
                  size: 16,
                  fill: '#2B3C47',
                }}
                className={!headerInput ? s.closeIcon : ''}
              />
            </Grid>
          )}

          <div className={s.content}>{children}</div>

          {footer && <footer className={s.footer}>{footer}</footer>}
        </ReactModal>
      </Fragment>
    );
  }
}

export default withStyles(s)(Modal);
