import getWin from './windowProvider';

const win = getWin();

const getAccessToken = () => {
  if (win && win.localStorage) {
    const token = win.localStorage.getItem('token');
    if (token) {
      // Once token is in localstorage remove it from global scope
      delete win.jwt;
      return token;
    }
    return win.jwt || null;
  }
  return null;
};

const setAccessToken = ({ accessToken }) => {
  if (win && win.localStorage) {
    win.localStorage.setItem('token', accessToken);
    // Localstorage delays writes
    win.jwt = accessToken;
  }
};

const removeAccessToken = () => {
  if (win && win.localStorage) {
    win.localStorage.removeItem('token');
    delete win.jwt;
  }
};

export { getAccessToken, setAccessToken, removeAccessToken };
