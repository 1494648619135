import { getProductPresentations, getCategoryPresentations } from '../helpers';
import {
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_PRICE_INTERVAL_START,
  GET_PRODUCT_PRICE_INTERVAL_SUCCESS,
  GET_PRODUCT_PRICE_INTERVAL_ERROR,
  GET_OCCASION_START,
  GET_OCCASION_SUCCESS,
  GET_OCCASION_ERROR,
  GET_CATEGORIES_SUCCESS,
  SORT_PRODUCTS_BY,
  UPDATE_FILTERS,
  UPDATE_SINGLE_FILTER,
  UPDATE_PRICE_INTERVAL,
  DELIVERY_METHODS,
  PRODUCT_TYPE,
} from '../constants';

export default function shop(state = {}, action) {
  switch (action.type) {
    case GET_PRODUCTS_START: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_PRODUCTS_SUCCESS: {
      const { data, isFirstLoad, ...rest } = action.payload;
      const flattenData = data.map(
        ({ id, minPrice, presentations, productType, deliveryMethods }) => {
          let retailPrice;

          if (productType === PRODUCT_TYPE.physicalProduct) {
            const physicalProductSku = deliveryMethods.find(
              ({ deliveryMethod }) =>
                deliveryMethod === DELIVERY_METHODS.PHYSICAL,
            )?.inventoryEntries[0];

            retailPrice =
              productType === PRODUCT_TYPE.physicalProduct
                ? physicalProductSku?.attributes.find(
                    ({ name }) =>
                      name.toLowerCase().includes('retail') &&
                      name.toLowerCase().includes('price'),
                  )
                : null;
          }

          const retailPriceIsDifferent =
            retailPrice && Number(retailPrice.value) !== minPrice;

          return {
            id,
            productType,
            deliveryMethods,
            retailPrice: retailPriceIsDifferent && retailPrice,
            price: minPrice,
            ...getProductPresentations(presentations),
          };
        },
      );

      return {
        ...state,
        data: isFirstLoad ? flattenData : [...state.data, ...flattenData],
        ...rest,
      };
    }

    case GET_PRODUCTS_ERROR: {
      return {
        ...state,
        ...action.payload,
        data: [],
      };
    }

    case GET_PRODUCT_PRICE_INTERVAL_START: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_PRODUCT_PRICE_INTERVAL_SUCCESS: {
      const { max, min, currency } = action.payload;
      const {
        priceInterval: { min: currentMin, max: currentMax },
      } = state;
      const floorMin = Math.floor(min);
      const ceilMax = Math.ceil(max);
      return {
        ...state,
        productPriceInterval: {
          currency,
          min: Number.isNaN(floorMin) ? null : floorMin,
          max: Number.isNaN(ceilMax) ? null : ceilMax,
        },
        priceInterval: {
          min: currentMin === undefined ? floorMin : currentMin,
          max: currentMax === undefined ? ceilMax : currentMax,
        },
      };
    }

    case GET_PRODUCT_PRICE_INTERVAL_ERROR: {
      const { currency } = action.payload;
      return {
        ...state,
        productPriceInterval: {
          currency,
          min: 0,
          max: 0,
        },
      };
    }

    case UPDATE_FILTERS: {
      const { filters } = state;
      const { params } = action.payload;
      return {
        ...state,
        filters: Object.assign(
          {},
          ...Object.keys(filters).map(group => ({
            [group]: Object.assign(
              {},
              ...Object.keys(filters[group]).map(key => {
                const { label, includeInPopular, categorySortingHome } =
                  filters[group][key] || {};
                return {
                  [key]: {
                    label,
                    categorySortingHome,
                    includeInPopular,
                    checked: params.filters[group].includes(key),
                  },
                };
              }),
            ),
          })),
        ),
        sortBy: params.orderBy,
        priceInterval: {
          min: parseInt(params.min, 10) || null,
          max: parseInt(params.max, 10) || null,
        },
      };
    }

    case UPDATE_PRICE_INTERVAL: {
      const { min, max, currency } = action.payload;
      return {
        ...state,
        priceInterval: {
          min,
          max,
          currency,
        },
      };
    }

    case UPDATE_SINGLE_FILTER: {
      const { id, group, checked } = action.payload;
      const updatedFilters = state.filters;
      updatedFilters[group][id].checked = checked;

      return {
        ...state,
        filters: {
          ...updatedFilters,
        },
      };
    }

    case GET_CATEGORIES_SUCCESS: {
      const {
        data: { recipient, regular, occasion, brand, locale, type },
      } = action.payload;

      const typeFilters = Object.assign(
        {},
        ...type.map(({ id, label }) => ({
          [id]: {
            ...state.filters.type[id],
            label,
          },
        })),
      );

      const recipientFilters = Object.assign(
        {},
        ...recipient.map(({ id, presentations, includeInPopular }) => {
          const pres = getCategoryPresentations(presentations);
          return {
            [id]: {
              includeInPopular,
              ...state.filters.recipient[id],
              label: pres.categoryTranslatedName || `Missing name (${locale})`,
              categorySortingHome:
                Number(pres.categorySortingHome) || undefined,
            },
          };
        }),
      );

      const categoryFilters = Object.assign(
        {},
        ...regular.map(({ id, presentations }) => {
          const pres = getCategoryPresentations(presentations);

          if (!pres.categoryHideInFilters) {
            return {
              [id]: {
                ...state.filters.category[id],
                label:
                  pres.categoryTranslatedName || `Missing name (${locale})`,
              },
            };
          }
          return null;
        }),
      );

      const occasionFilters = Object.assign(
        {},
        ...occasion.map(({ id, presentations }) => {
          const pres = getCategoryPresentations(presentations);
          if (!pres.categoryHideInFilters) {
            return {
              [id]: {
                ...state.filters.occasion[id],
                label:
                  pres.categoryTranslatedName || `Missing name (${locale})`,
              },
            };
          }
          return null;
        }),
      );

      const brandFilters = Object.assign(
        {},
        ...brand.map(({ id, label }) => ({
          [id]: {
            ...state.filters.brand[id],
            label,
          },
        })),
      );

      return {
        ...state,
        filters: {
          type: typeFilters,
          recipient: recipientFilters,
          category: categoryFilters,
          occasion: occasionFilters,
          brand: brandFilters,
        },
      };
    }

    case SORT_PRODUCTS_BY: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_OCCASION_START: {
      return {
        ...state,
        occasion: {
          data: [],
          loading: true,
        },
      };
    }

    case GET_OCCASION_SUCCESS: {
      const { data, loading } = action.payload;
      const flattenData = data.map(
        ({ id, minPrice, presentations, productType }) => ({
          id,
          productType,
          price: minPrice,
          ...getProductPresentations(presentations),
        }),
      );

      return {
        ...state,
        occasion: {
          data: flattenData,
          loading,
        },
      };
    }

    case GET_OCCASION_ERROR: {
      const { occasion, ...rest } = action.payload;
      return {
        ...state,
        occasion: {
          [occasion]: {
            ...rest,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
