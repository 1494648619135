import {
  GET_COUNTRY_PRESENTATIONS_MESSAGE_START,
  GET_COUNTRY_PRESENTATIONS_MESSAGE_ERROR,
  GET_COUNTRY_PRESENTATIONS_MESSAGE_SUCCESS,
  CLOSE_SERVICE_MESSAGE,
} from '../constants';

export default function config(state = {}, action) {
  switch (action.type) {
    case GET_COUNTRY_PRESENTATIONS_MESSAGE_START: {
      return {
        ...state,
      };
    }

    case GET_COUNTRY_PRESENTATIONS_MESSAGE_SUCCESS: {
      const { presentations, countryCode, locale, loading } = action.payload;
      const mappedPresentations = presentations.reduce(
        (acc, { presentationKey, textContent, image, languageCode }) => {
          if (!acc[presentationKey] || languageCode.toLowerCase() === locale) {
            acc[presentationKey] = image || textContent;
          }
          return acc;
        },
        {},
      );
      const showMessage = !!(
        mappedPresentations.countryServiceMessageTitle &&
        mappedPresentations.countryServiceMessageBody
      );
      return {
        ...state,
        [countryCode]: {
          title: mappedPresentations.countryServiceMessageTitle || undefined,
          body: mappedPresentations.countryServiceMessageBody || undefined,
          link: mappedPresentations.countryServiceMessageLink || undefined,
          linkLabel:
            mappedPresentations.countryServiceMessageLinkLabel || undefined,
          closable:
            mappedPresentations.countryServiceMessageClosable === 'true',
          showMessage,
        },
        loading,
      };
    }

    case GET_COUNTRY_PRESENTATIONS_MESSAGE_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CLOSE_SERVICE_MESSAGE: {
      const { showMessage, countryCode } = action.payload;
      return {
        ...state,
        [countryCode]: {
          ...state[countryCode],
          showMessage,
        },
      };
    }

    default: {
      return state;
    }
  }
}
