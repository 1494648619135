// eslint-disable-next-line import/prefer-default-export
export const trimStringProperties = obj => {
  const object = { ...obj };
  Object.keys(object).forEach(
    // eslint-disable-next-line no-return-assign
    k =>
      (object[k] =
        typeof object[k] === 'string' ? object[k].trim() : object[k]),
  );

  return object;
};
