/* eslint-disable*/
const formatJsLoggerHelper = err => {
  const errCode = err?.code;
  if (errCode === 'MISSING_TRANSLATION' || errCode == 'FORMAT_ERROR' || err?.message?.startsWith('[@formatjs/intl Error')) {
    console.log('Missing translation', err.descriptor?.id);
    return false;
  }
  throw err;
};
export {
  formatJsLoggerHelper
};