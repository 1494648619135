import algoliasearch from 'algoliasearch/lite';

const searchClient = ({ applicationId, apiKey, prefix }) => {
  const algoliaClient = algoliasearch(applicationId, apiKey);
  return {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }
      return algoliaClient.search(requests);
    },
    indexPrefix: prefix,
  };
};

export default searchClient;
