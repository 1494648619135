// @flow

import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import apolloLogger from 'apollo-link-logger';
import createCache from './createCache';
import { getAccessToken } from '../../helpers/token';

const defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const authenticationLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const createLink = ({ graphqlHost, cmsHost }) =>
  from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.warn(`[Network error]: ${networkError}`);
    }),
    ...(__DEV__ ? [apolloLogger] : []),
    authenticationLink,
    new HttpLink({
      uri: operation => {
        const { service } = operation.getContext();

        if (service === 'cms') {
          return `${cmsHost}/graphql`;
        }

        // Defaults to api service
        return `${graphqlHost}/graphql`;
      },
      credentials: 'same-origin',
    }),
  ]);

const cache = createCache();

export default function createApolloClient({ graphqlHost, cmsHost }) {
  return new ApolloClient({
    link: createLink({ graphqlHost, cmsHost }),
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
    defaultOptions,
  });
}
