import { TOGGLE_MOBILE_MENU, TOGGLE_HEADER } from '../constants';

export default function mobileMenu(state = {}, action) {
  switch (action.type) {
    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };

    case TOGGLE_HEADER:
      return {
        ...state,
        headerTop: action.payload,
      };
    default:
      return state;
  }
}
