import {
  SET_LOCALE_START,
  SET_LOCALE_SUCCESS,
  SET_LOCALE_ERROR,
  SET_COUNTRY,
  SET_CURRENCY,
} from '../constants';

export default function intl(state = {}, action) {
  switch (action.type) {
    case SET_LOCALE_START: {
      const locale = state[action.payload.locale]
        ? action.payload.locale
        : state.locale;
      return {
        ...state,
        locale,
      };
    }

    case SET_LOCALE_SUCCESS: {
      const { locale, messages } = action.payload;
      return {
        ...state,
        locale,
        messages: {
          ...state.messages,
          [locale]: messages || state.messages[locale],
        },
      };
    }

    case SET_LOCALE_ERROR: {
      return {
        ...state,
      };
    }

    case SET_COUNTRY: {
      const { countryCode } = action.payload;
      return {
        ...state,
        countryCode,
      };
    }

    case SET_CURRENCY: {
      const { currency } = action.payload;
      return {
        ...state,
        currency,
      };
    }

    default: {
      return state;
    }
  }
}
