/* eslint-disable global-require */
import { toggleMobileMenu } from '../actions/header';
import { urlPrefix } from '../helpers';
import {
  ACCOUNT_TYPE,
  DEFAULT_PAGE_TITLE,
  WELCOME_PATH,
  MY_PATH,
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  DISPLAY_CATALOG,
} from '../constants';
import { getSalesFlowForCountry } from '../actions/intl';

const requireModes = ({ modes, redirect }) => ({ next, store }) => {
  const {
    user,
    intl: { locale, countryCode, currency } = {},
  } = store.getState();
  if (Array.isArray(modes) && modes.includes(user.mode)) {
    return next();
  }
  return {
    status: 302,
    redirect: urlPrefix(redirect || WELCOME_PATH, {
      countryCode,
      locale,
      currency,
    }),
  };
};

const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/register',
      load: () => import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/signin-oidc',
      load: () => import(/* webpackChunkName: 'signin-oidc' */ './signinOidc'),
    },
    {
      path: '/logout',
      load: () => import(/* webpackChunkName: 'logout' */ './logout'),
    },
    // styled not-found page server can redirect to
    {
      path: '/not-found',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
    {
      path: '/signout-callback-oidc',
      action: () => {
        return {
          redirect: '/',
        };
      },
    },
    {
      path: '/',
      // Redirect to localized top route
      action: async ({ store }) => {
        const {
          intl: { locale, countryCode, currency } = {},
        } = store.getState();

        return {
          redirect: urlPrefix('', {
            countryCode,
            locale,
            currency,
          }),
        };
      },
    },
    {
      path: '/greeting/:greetingToken',
      action: ({ store, params: { greetingToken } }) => {
        const {
          intl: { countryCode } = {},
          config: { salesChannels },
        } = store.getState();
        const salesFlow = getSalesFlowForCountry({
          countryCode,
          salesChannels,
        });
        return {
          redirect: urlPrefix(`/greeting/${greetingToken}`, {
            locale: (salesFlow && salesFlow.defaultLocale) || DEFAULT_LOCALE,
            countryCode: (salesFlow && countryCode) || DEFAULT_COUNTRY,
            currency:
              (salesFlow && salesFlow.defaultCurrency) || DEFAULT_CURRENCY,
          }),
        };
      },
    },
    {
      path: '/redeem/:voucherCode',
      action: ({ store, params: { voucherCode } }) => {
        const {
          intl: { countryCode } = {},
          config: { salesChannels },
        } = store.getState();
        const salesFlow = getSalesFlowForCountry({
          countryCode,
          salesChannels,
        });
        return {
          redirect: urlPrefix(`/redeem/${voucherCode}`, {
            locale: (salesFlow && salesFlow.defaultLocale) || DEFAULT_LOCALE,
            countryCode: (salesFlow && countryCode) || DEFAULT_COUNTRY,
            currency:
              (salesFlow && salesFlow.defaultCurrency) || DEFAULT_CURRENCY,
          }),
        };
      },
    },
    {
      path: '/portal/:slug/:voucherCode',
      action: ({ store, params: { slug, voucherCode } }) => {
        const {
          intl: { countryCode } = {},
          config: { salesChannels },
        } = store.getState();
        const salesFlow = getSalesFlowForCountry({
          countryCode,
          salesChannels,
        });
        return {
          redirect: urlPrefix(`/portal/${slug}/${voucherCode}`, {
            locale: (salesFlow && salesFlow.defaultLocale) || DEFAULT_LOCALE,
            countryCode: (salesFlow && countryCode) || DEFAULT_COUNTRY,
            currency:
              (salesFlow && salesFlow.defaultCurrency) || DEFAULT_CURRENCY,
          }),
        };
      },
    },
    {
      path: '/:locale/:countryCode',
      children: [
        {
          path: '',
          action: ({ store, params: { countryCode } }) => {
            const {
              config: { salesChannels },
            } = store.getState();
            const salesFlow = getSalesFlowForCountry({
              countryCode,
              salesChannels,
            });

            return {
              redirect: urlPrefix('', {
                locale:
                  (salesFlow && salesFlow.defaultLocale) || DEFAULT_LOCALE,
                countryCode: (salesFlow && countryCode) || DEFAULT_COUNTRY,
                currency:
                  (salesFlow && salesFlow.defaultCurrency) || DEFAULT_CURRENCY,
              }),
            };
          },
        },
        {
          path: '/verify-owner/:type/:token',
          action: ({ store, params: { type, token } }) => {
            const {
              intl: { countryCode } = {},
              config: { salesChannels },
            } = store.getState();
            const salesFlow = getSalesFlowForCountry({
              countryCode,
              salesChannels,
            });

            return {
              redirect: urlPrefix(`/verify-owner/${type}/${token}`, {
                locale:
                  (salesFlow && salesFlow.defaultLocale) || DEFAULT_LOCALE,
                countryCode: (salesFlow && countryCode) || DEFAULT_COUNTRY,
                currency:
                  (salesFlow && salesFlow.defaultCurrency) || DEFAULT_CURRENCY,
              }),
            };
          },
        },
      ],
    },
    {
      path: '/:locale/:countryCode/:currency',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'home' */ './home'),
        },
        {
          path: '/welcome',
          load: () => import(/* webpackChunkName: 'welcome' */ './welcome'),
        },
        {
          path: '/:salesChannelId?/verify-owner/:type/:token/:pin?',
          load: () =>
            import(
              /* webpackChunkName: 'verify-giftcard-owner' */ './verify-giftcard-owner'
            ),
        },
        {
          path: `${DISPLAY_CATALOG}/:urlSlug?`,
          load: () =>
            import(
              /* webpackChunkName: 'display-catalog' */ './display-catalog'
            ),
        },
        {
          path: '/:salesChannelId?/verify-owner',
          load: () =>
            import(
              /* webpackChunkName: 'verify-giftcard-owner-redirect' */ './verify-giftcard-owner-redirect'
            ),
        },

        {
          path: '/:salesChannelId?/giftcard',
          load: () => import(/* webpackChunkName: 'giftcard' */ './giftcard'),
        },
        {
          path: '/:salesChannelId?/giftcard-redeem',
          load: () =>
            import(
              /* webpackChunkName: 'giftcard-redeem' */ './giftcard-redeem'
            ),
        },
        {
          path: '/giftcard/bundle',
          load: () =>
            import(
              /* webpackChunkName: 'giftcard-bundle' */ './giftcard-bundle'
            ),
        },
        {
          path: '/shop',
          children: [
            {
              path: '',
              load: () => import(/* webpackChunkName: 'shop' */ './shop'),
            },
            {
              path: '/:product/:id',
              load: () => import(/* webpackChunkName: 'product' */ './product'),
            },
          ],
        },
        {
          path: '/basket',
          children: [
            {
              path: '',
              load: () => import(/* webpackChunkName: 'basket' */ './basket'),
            },
            {
              path: '/delivery',
              load: () =>
                import(
                  /* webpackChunkName: 'basketDelivery' */ './basketDelivery'
                ),
            },
          ],
        },
        {
          path: '/basket/psp/:status',
          load: () => import(/* webpackChunkName: 'basketPsp' */ './basketPsp'),
        },
        {
          path: '/redeem',
          load: () => import(/* webpackChunkName: 'redeem' */ './redeem'),
        },
        {
          path: '/redeem/:voucherCode',
          load: () => import(/* webpackChunkName: 'redeem' */ './redeem'),
        },
        {
          path: '/occasion/:occasion/:id',
          load: () => import(/* webpackChunkName: 'occasion' */ './occasion'),
        },
        {
          path: '/occasion/:id',
          load: () => import(/* webpackChunkName: 'occasion' */ './occasion'),
        },
        {
          path: '/occasion',
          load: () => import(/* webpackChunkName: 'occasion' */ './occasions'),
        },
        {
          path: '/order/:id/psp/:basketId',
          load: () => import(/* webpackChunkName: 'orderPsp' */ './orderPsp'),
        },
        {
          path: '/order/:id',
          load: () => import(/* webpackChunkName: 'order' */ './order'),
        },
        {
          path: '/portal/:slug',
          load: () => import(/* webpackChunkName: 'portal' */ './portal'),
        },
        {
          path: '/portal/:slug/:voucherCode',
          load: () => import(/* webpackChunkName: 'portal' */ './portal'),
        },
        {
          path: '/greeting/:greetingToken',
          load: () => import(/* webpackChunkName: 'greeting' */ './greeting'),
        },
        {
          path: '/business',
          action: requireModes({ modes: [ACCOUNT_TYPE.B2B, ACCOUNT_TYPE.B2C] }),
          children: [
            {
              path: '/my',

              children: [
                {
                  path: '',
                  load: () =>
                    import(/* webpackChunkName: 'businessMy' */ './businessMy'),
                },
                {
                  path: '/account',
                  action: requireModes({
                    modes: [ACCOUNT_TYPE.B2C],
                    redirect: MY_PATH,
                  }),
                  load: () =>
                    import(
                      /* webpackChunkName: 'businessAccount' */ './businessAccount'
                    ),
                },
                {
                  path: '/account-create',
                  load: () =>
                    import(
                      /* webpackChunkName: 'businessAccountCreate' */ './businessAccountCreate'
                    ),
                },
                {
                  path: '/orders',
                  action: requireModes({
                    modes: [ACCOUNT_TYPE.B2C],
                    redirect: MY_PATH,
                  }),
                  load: () =>
                    import(
                      /* webpackChunkName: 'businessOrders' */ './businessOrders'
                    ),
                },
                {
                  path: '/orders/:id',
                  action: requireModes({
                    modes: [ACCOUNT_TYPE.B2C],
                    redirect: MY_PATH,
                  }),
                  load: () =>
                    import(
                      /* webpackChunkName: 'businessOrder' */ './businessOrder'
                    ),
                },
                {
                  path: '/department',
                  load: () =>
                    import(
                      /* webpackChunkName: 'businessDepartment' */ './businessDepartment'
                    ),
                },
              ],
            },
          ],
        },
        {
          path: '/brand',
          load: () => import(/* webpackChunkName: 'page' */ './brand'),
        },
        {
          path: '/:pageName',
          load: () => import(/* webpackChunkName: 'page' */ './page'),
        },
      ],
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next, store }) {
    const { getState, dispatch } = store;
    const {
      header: { main },
    } = getState();
    // Execute each child route until one of them return the result
    const route = await next();
    // Provide default values for title, description etc.
    route.title = route.title || DEFAULT_PAGE_TITLE;
    route.description = route.description || '';

    // if the main mobile menu is open, we will close it
    if (main) {
      await dispatch(toggleMobileMenu('main'));
    }

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
