/* eslint-disable import/prefer-default-export */
import {
  GET_PAGE_START,
  GET_PAGE_SUCCESS,
  GET_PAGE_ERROR,
  SALES_CHANNELS,
} from '../constants';
import queryPagePresentationKeys from './queries/getPagePresentationKeys.graphql';
import { getFromState } from '../helpers';

export function getPagePresentationKeys({ pageName, salesChannelIdParam }) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale },
      config: { salesChannelId = SALES_CHANNELS.B2B },
    } = getState();

    dispatch({
      type: GET_PAGE_START,
      meta: {
        salesChannelId: salesChannelIdParam || salesChannelId,
        locale,
        pageName,
      },
    });

    try {
      const {
        data: { presentationsByPageName },
      } = await client.query({
        query: queryPagePresentationKeys,
        variables: {
          input: {
            pageName,
            salesChannelId: salesChannelIdParam || salesChannelId,
            expectedLanguage: locale,
          },
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      dispatch({
        type: GET_PAGE_SUCCESS,
        payload: {
          presentations: presentationsByPageName,
          pageName,
        },
      });

      return getFromState(getState(), 'page');
    } catch (error) {
      dispatch({
        type: GET_PAGE_ERROR,
        payload: {
          error,
          pageName,
        },
      });
      return getFromState(getState(), 'page');
    }
  };
}
