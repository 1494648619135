import { GET_PAGE_START, GET_PAGE_SUCCESS, GET_PAGE_ERROR } from '../constants';
import { getPagePresentations } from '../helpers';

export default function page(state = null, action) {
  switch (action.type) {
    case GET_PAGE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PAGE_SUCCESS: {
      const { presentations, pageName } = action.payload;
      const mappedPresentations = getPagePresentations(presentations);
      return {
        ...state,
        byName: {
          ...state.byName,
          [pageName]: mappedPresentations,
        },
        loading: false,
      };
    }
    case GET_PAGE_ERROR: {
      const { pageName, error } = action.payload;
      return {
        ...state,
        byName: {
          [pageName]: {},
          ...state.byName,
        },
        error,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
