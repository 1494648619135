import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  name,
  fill,
  size,
  closeToast,
  viewBox,
  pathProps,
  ...props
}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={viewBox}
    fill={fill}
    {...props}
  >
    <path {...pathProps} d={name} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  fill: PropTypes.string,
  closeToast: PropTypes.func,
  viewBox: PropTypes.string,
  pathProps: PropTypes.shape(),
};

Icon.defaultProps = {
  size: 16,
  fill: '#2b3c47',
  closeToast: null,
  viewBox: '0 0 1024 1024',
  pathProps: {},
};

export default Icon;
