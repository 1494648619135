import {
  CREATE_BASKET_START,
  CREATE_BASKET_SUCCESS,
  CREATE_BASKET_ERROR,
  GET_BASKET_START,
  GET_BASKET_SUCCESS,
  GET_BASKET_ERROR,
  UPDATE_BASKET_START,
  UPDATE_BASKET_SUCCESS,
  UPDATE_BASKET_ERROR,
  BASKET_ERROR_TOASTIFY,
  ADD_PRODUCT_START,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  GET_SHIPPINGQUOTE_START,
  GET_SHIPPINGQUOTE_SUCCESS,
  GET_SHIPPINGQUOTE_ERROR,
  SET_SHIPPINGQUOTE_SUCCESS,
  SET_SHIPPINGQUOTE_ERROR,
  FINALIZE_BASKET_START,
  FINALIZE_BASKET_SUCCESS,
  FINALIZE_BASKET_ERROR,
  ADD_REDEEM_START,
  ADD_REDEEM_SUCCESS,
  ADD_REDEEM_ERROR,
  GET_ORDER_START,
  GET_ORDER_ERROR,
  GET_ORDER_SUCCESS,
  GET_LOGGEDIN_USER_SUCCESS,
  ACCOUNT_TYPE,
  SET_DEPARTMENT,
  PAYMENT_METHODS,
  REMOVE_REDEEM_START,
  REMOVE_REDEEM_SUCCESS,
  REMOVE_REDEEM_ERROR,
  SET_LOGGED_OUT,
  DEPARTMENT_USER_STATUS,
  SET_COUNTRY,
  ORDER_REFERENCE,
} from '../constants';
import {
  getProductPresentations,
  flattenDeliveryMethods,
  flattenBasketData,
  flattenDeliveryForms,
  addCompletelyOutOfStockInfo,
} from '../helpers';

export default function basket(state = {}, action) {
  switch (action.type) {
    case CREATE_BASKET_START: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case CREATE_BASKET_ERROR: {
      const { error, message, loading } = action.payload;
      return {
        id: null,
        data: [],
        formData: {},
        deliveryForms: {},
        acceptTerms: false,
        itemsCount: 0,
        presentationData: {},
        order: {
          presentationData: {},
        },
        billingForm: {
          ...state.billingForm,
        },
        error,
        message,
        loading,
      };
    }

    case GET_BASKET_SUCCESS: {
      const {
        buyer,
        deliveries,
        basketPresentations,
        defaultCountryCode,
        loading,
        basketLoading,
        stockInfos,
        ...rest
      } = action.payload;
      const { accountId, accountType, name, email, phone, address } = buyer;
      const { countryCode, postCode, city, line1, line2, attention } =
        address || {};
      const data = flattenBasketData(deliveries);
      const itemsCount = data.reduce(
        (productCount, delivery) => productCount + delivery.quantity,
        0,
      );
      const deliveryForms = flattenDeliveryForms(data);
      const presentationData = basketPresentations.reduce(
        (
          acc,
          {
            id,
            presentations,
            deliveryMethods,
            defaultPriceCurrency,
            productType,
          },
        ) => {
          acc[id] = {
            deliveryMethods: flattenDeliveryMethods(deliveryMethods),
            presentations: getProductPresentations(presentations),
            defaultPriceCurrency,
            stockInfo: addCompletelyOutOfStockInfo(stockInfos[id]),
            productType,
          };
          return acc;
        },
        {},
      );

      // Remove null and undefined values, should be taken from state
      const billingForm = Object.entries({
        accountId,
        accountType,
        name,
        email,
        phone,
        countryCode:
          state.billingForm.countryCode || countryCode || defaultCountryCode,
        postCode,
        city,
        line1,
        line2,
        attention,
      }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});

      return {
        ...state,
        ...rest,
        data,
        itemsCount,
        presentationData,
        deliveryForms,
        billingForm: {
          ...state.billingForm,
          ...billingForm,
        },
        loading,
      };
    }

    case ADD_PRODUCT_SUCCESS: {
      const { buyer, deliveries, ...rest } = action.payload;
      const {
        accountId,
        accountType,
        name,
        email,
        phone,
        address: { ...addressProps },
      } = buyer;
      const data = flattenBasketData(deliveries);
      const itemsCount = data.reduce(
        (productCount, delivery) => productCount + delivery.quantity,
        0,
      );
      return {
        ...state,
        ...rest,
        data,
        itemsCount,
        formData: {
          ...state.formData,
          accountId,
          accountType,
          name,
          phone,
          email,
          ...addressProps,
        },
      };
    }
    case SET_SHIPPINGQUOTE_SUCCESS:
    case ADD_REDEEM_SUCCESS:
    case UPDATE_BASKET_SUCCESS: {
      const { deliveries, ...rest } = action.payload;
      const data = flattenBasketData(deliveries);
      const itemsCount = data.reduce(
        (productCount, delivery) => productCount + delivery.quantity,
        0,
      );
      const deliveryForms = flattenDeliveryForms(data);
      return {
        ...state,
        ...rest,
        data,
        itemsCount,
        deliveryForms,
      };
    }

    case GET_ORDER_START: {
      // Clear basket at the same time, keep billing form in case another order is made
      return {
        data: [],
        deliveryForms: {},
        billingForm: {
          ...state.billingForm,
        },
        order: {
          ...action.payload,
        },
        itemsCount: 0,
      };
    }

    case SET_LOGGED_OUT: {
      // Need to clear basket upon logout as new anonymous basket is needed
      return {
        data: [],
        deliveryForms: {},
        billingForm: {},
        order: {},
        itemsCount: 0,
      };
    }

    case GET_ORDER_SUCCESS: {
      const {
        id,
        lastUpdatedAt,
        deliveries,
        presentations,
        ...rest
      } = action.payload;

      const presentationData = Object.assign(
        {},
        ...presentations.map(presentation => ({
          [presentation.id]: getProductPresentations(
            presentation.presentations,
          ),
        })),
      );

      return {
        ...state,
        order: {
          orderId: id,
          orderConfirmationDate: lastUpdatedAt,
          ...rest,
          deliveries,
          presentationData,
        },
      };
    }

    case GET_ORDER_ERROR: {
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
      };
    }

    case GET_LOGGEDIN_USER_SUCCESS: {
      // When user logs in (auth), prefill basket buyer info if not already set in the basket
      const {
        email,
        name,
        phone,
        countryCode,
        city,
        postCode,
        line1,
        line2,
        attention,
      } = action.payload.user;

      return {
        ...state,
        billingForm: {
          email,
          name,
          phone,
          countryCode,
          city,
          postCode,
          line1,
          line2,
          attention,
          ...Object.entries(state.billingForm).reduce(
            (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
            {},
          ),
        },
      };
    }

    case SET_DEPARTMENT: {
      // When B2B user logs in, overwrite basket buyer info
      const { account } = action.payload;
      if (!account) {
        return {
          ...state,
        };
      }
      const {
        departmentId,
        nameOverride,
        emailOverride,
        phoneNumberOverride,
        status,
      } = account;

      let { deliveryAddressOverride = {}, department = {} } = account;
      deliveryAddressOverride = deliveryAddressOverride || {};
      department = department || {};

      const billingForm = {
        accountId: departmentId,
        accountType: ACCOUNT_TYPE.B2B,
        name: department.name || nameOverride,
        email: emailOverride || department.email,
        phone: phoneNumberOverride || department.phoneNumber,
        countryCode: deliveryAddressOverride.countryCode,
        postCode: deliveryAddressOverride.postCode,
        city: deliveryAddressOverride.city,
        line1: deliveryAddressOverride.line1,
        line2: deliveryAddressOverride.line2,
        attention: deliveryAddressOverride.attention || nameOverride,
      };
      return {
        ...state,
        billingForm: {
          ...Object.entries(state.billingForm).reduce(
            (a, [k, v]) => (v == null ? a : { ...a, [k]: v }),
            {},
          ),
          ...billingForm,
        },
        paymentMethod:
          department.approvedForInvoice &&
          status === DEPARTMENT_USER_STATUS.ACTIVE
            ? PAYMENT_METHODS.INVOICE
            : PAYMENT_METHODS.PSP,
      };
    }

    case CREATE_BASKET_SUCCESS:
    case GET_BASKET_START:
    case GET_BASKET_ERROR:
    case ADD_PRODUCT_START:
    case ADD_PRODUCT_ERROR:
    case UPDATE_BASKET_START:
    case UPDATE_BASKET_ERROR:
    case FINALIZE_BASKET_START:
    case FINALIZE_BASKET_SUCCESS:
    case FINALIZE_BASKET_ERROR:
    case GET_SHIPPINGQUOTE_START:
    case GET_SHIPPINGQUOTE_ERROR:
    case SET_SHIPPINGQUOTE_ERROR:
    case ADD_REDEEM_START:
    case ADD_REDEEM_ERROR:
    case REMOVE_REDEEM_START:
    case REMOVE_REDEEM_SUCCESS:
    case REMOVE_REDEEM_ERROR: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    case BASKET_ERROR_TOASTIFY: {
      const { errorToastify, errorToastifyFull } = action.payload;
      return {
        ...state,
        errorToastify,
        errorToastifyFull,
      };
    }

    case GET_SHIPPINGQUOTE_SUCCESS: {
      const { quotes } = action.payload;

      return {
        ...state,
        quotes: {
          ...state.quotes,
          ...quotes,
        },
      };
    }

    case 'SET_BUYER': {
      const { name, value } = action.payload;
      return {
        ...state,
        billingForm: {
          ...state.billingForm,
          [name]: value,
        },
      };
    }

    case 'EDIT_DELIVERY': {
      const { orderLine, name, value } = action.payload;
      return {
        ...state,
        deliveryForms: {
          ...state.deliveryForms,
          [orderLine]: {
            ...state.deliveryForms[orderLine],
            [name]: value,
          },
        },
      };
    }

    case 'ACCEPT_TERMS': {
      const { value } = action.payload;
      return {
        ...state,
        acceptTerms: value,
      };
    }

    case 'PAYMENT_METHOD': {
      const { value } = action.payload;
      return {
        ...state,
        paymentMethod: value,
      };
    }

    case ORDER_REFERENCE: {
      const { value } = action.payload;
      return { ...state, reference: value };
    }

    case SET_COUNTRY: {
      const { countryCode, mode } = action.payload;

      if (mode !== ACCOUNT_TYPE.B2B) {
        return {
          ...state,
          billingForm: {
            ...state.billingForm,
            countryCode,
          },
        };
      }
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
