export const TAG_MANAGER_EVENTS = {
  genericGAEvent: 'genericGAEvent',
  addToCart: 'addToCart',
  removeFromCart: 'removeFromCart',
  superGiftCardClickEvent: 'superGiftCardClickEvent',
  seeAllGiftCardEvent: 'seeAllGiftCardEvent',
  productClick: 'productClick',
  purchase: 'purchase',
  checkout: 'checkout',
};

export const TAG_MANAGER_LOGIN = {
  b2b: 'business Login',
  b2c: 'not logged in',
};
// eslint-disable-next-line import/prefer-default-export
export const triggerEcommerceEvent = ({ eventName, eventData }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      ...eventData,
    },
  });
};

export const triggerGenericGAEvent = ({ eventData }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: TAG_MANAGER_EVENTS.genericGAEvent,
    ...eventData,
  });
};

export const triggerSuperGiftCardClickEvent = ({ eventData }) => {
  triggerEcommerceEvent({
    eventName: TAG_MANAGER_EVENTS.superGiftCardClickEvent,
    eventData,
  });
};

export const triggerSeeAllGiftCardClickEvent = ({ eventData }) => {
  triggerEcommerceEvent({
    eventName: TAG_MANAGER_EVENTS.seeAllGiftCardEvent,
    eventData,
  });
};

export const productClickEvent = ({ eventData }) => {
  triggerEcommerceEvent({
    eventName: TAG_MANAGER_EVENTS.productClick,
    eventData,
  });
};
export const purchaseEvent = ({ eventData }) => {
  triggerEcommerceEvent({ eventName: TAG_MANAGER_EVENTS.purchase, eventData });
};
export const checkoutEvent = ({ eventData }) => {
  triggerEcommerceEvent({ eventName: TAG_MANAGER_EVENTS.checkout, eventData });
};
export const addToCartEvent = ({ eventData }) => {
  triggerEcommerceEvent({ eventName: TAG_MANAGER_EVENTS.addToCart, eventData });
};
export const removeFromCartEvent = ({ eventData }) => {
  triggerEcommerceEvent({
    eventName: TAG_MANAGER_EVENTS.removeFromCart,
    eventData,
  });
};
export const triggerUserLoginStatusEvent = ({ isB2bUser }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    loginStatus: isB2bUser ? TAG_MANAGER_LOGIN.b2b : TAG_MANAGER_LOGIN.b2c,
  });
};
