import {
  GET_GREETING_START,
  GET_GREETING_SUCCESS,
  GET_GREETING_ERROR,
  UPDATE_GREETING_START,
  UPDATE_GREETING_SUCCESS,
  UPDATE_GREETING_ERROR,
} from '../constants';

export default function greeting(state = {}, action) {
  switch (action.type) {
    case GET_GREETING_START: {
      const { greetingLoading } = action.payload;
      return {
        ...state,
        greetingLoading,
      };
    }

    case GET_GREETING_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_GREETING_ERROR: {
      const { greetingLoading, errors } = action.payload;
      return {
        ...state,
        errors,
        greetingLoading,
      };
    }
    case UPDATE_GREETING_START: {
      const { greetingLoading } = action.payload;
      return {
        ...state,
        greetingLoading,
      };
    }
    case UPDATE_GREETING_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_GREETING_ERROR: {
      const { greetingLoading, errors } = action.payload;
      return {
        ...state,
        errors,
        greetingLoading,
      };
    }
    default: {
      return state;
    }
  }
}
