import { SET_LOGGED_OUT } from '../constants';

const loadingReducer = (state = {}, action) => {
  const {
    loading,
    loadingMessage,
    authLoading,
    basketLoading,
    initialAuth,
    initialBasket,
    redirecting,
    orderLoading,
  } = action.payload || {};
  const newState = {
    ...state,
  };
  if (typeof redirecting === 'boolean') {
    newState.redirecting = redirecting;
  }
  if (typeof loading === 'boolean') {
    newState.loading = loading;
  }
  if (typeof loadingMessage === 'string') {
    newState.loadingMessage = loadingMessage;
  }
  if (typeof authLoading === 'boolean') {
    newState.authLoading = authLoading;
  }
  if (typeof basketLoading === 'boolean') {
    newState.basketLoading = basketLoading;
  }
  if (typeof orderLoading === 'boolean') {
    newState.orderLoading = orderLoading;
  }
  if (typeof initialAuth === 'boolean') {
    newState.initialAuth = initialAuth;
  }
  if (typeof initialBasket === 'boolean') {
    newState.initialBasket = initialBasket;
  }
  if (action.type === SET_LOGGED_OUT) {
    newState.initialAuth = null;
    newState.initialBasket = null;
  }
  return newState;
};

export default loadingReducer;
