import {
  GET_ACCOUNT_START,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  GET_ORDER_START,
  GET_ORDERS_START,
  GET_ORDERS_ERROR,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_DOWNLOAD_STATE_SUCCESS,
  GET_ORDERS_DOWNLOAD_LINK_SUCCESS,
  GET_MY_ORDER_START,
  GET_MY_ORDER_ERROR,
  GET_MY_ORDER_SUCCESS,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_START,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  TOGGLE_DELIVERY_ADDRESS,
  SET_DEPARTMENT,
  SET_ACCOUNT_FIELD,
  SET_DELIVERY_ADDRESS_FIELD,
  SET_DEPARTMENT_ADDRESS_FIELD,
  SET_DEPARTMENT_DELIVERY_ADDRESS_FIELD,
  RESET_DEPARTMENT_FORM,
  SET_LOGGED_OUT,
  GET_LOGGEDIN_USER_SUCCESS,
  ACCOUNT_TYPE,
  GET_BASKET_SUCCESS,
  UPDATE_BASKET_SUCCESS,
  ADD_REDEEM_SUCCESS,
  REMOVE_REDEEM_SUCCESS,
} from '../constants';

const mapDepartment = ({ accounts, account, state }) => {
  if (!account) {
    return state;
  }

  const {
    id,
    subjectId,
    nameOverride,
    emailOverride,
    phoneNumberOverride,
    deliveryAddressOverride,
    departmentId,
    department: {
      name,
      companyRegistrationId,
      deliveryAddress,
      ...restDepartment
    },
  } = account;

  return {
    ...state,
    mode: ACCOUNT_TYPE.B2B,
    account,
    accounts,
    id,
    subjectId,
    departmentId,
    formData: {
      ...state.formData,
      departmentId,
      ...restDepartment,
      name,
      companyRegistrationId,
      deliveryAddress,
      id,
      nameOverride,
      emailOverride,
      phoneNumberOverride,
      deliveryAddressOverride,
      showDeliveryAddress: !!(deliveryAddress && deliveryAddress.line1),
    },
    loading: false,
  };
};

export default function user(state = {}, action) {
  switch (action.type) {
    case GET_ACCOUNT_SUCCESS: {
      const { accounts } = action.payload;
      return {
        ...state,
        accounts,
        loading: false,
      };
    }

    case GET_ACCOUNT_START:
    case CREATE_ACCOUNT_START:
    case UPDATE_ACCOUNT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case CREATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS: {
      const { department, user: departmentUser } = action.payload;
      const { id: departmentId, ...restDepartment } = department;
      const myDepartmentUser = {
        ...departmentUser,
        departmentId,
        department,
      };
      const newAccounts = [...state.accounts];
      const ix = newAccounts.findIndex(a => a.departmentId === departmentId);
      if (ix === -1) {
        newAccounts.push(myDepartmentUser);
      } else {
        newAccounts.splice(ix, 1, myDepartmentUser);
      }

      return {
        ...state,
        accounts: newAccounts,
        account: {
          ...state.account,
          ...myDepartmentUser,
        },
        formData: {
          ...state.formData,
          ...restDepartment,
          departmentId,
          ...departmentUser,
        },
        loading: false,
      };
    }

    case GET_ACCOUNT_ERROR:
    case CREATE_ACCOUNT_ERROR:
    case UPDATE_ACCOUNT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_LOGGEDIN_USER_SUCCESS: {
      const mode =
        state.mode === ACCOUNT_TYPE.ANONYMOUS ? ACCOUNT_TYPE.B2C : state.mode;
      return {
        ...state,
        mode,
      };
    }

    case GET_ORDER_START:
    case GET_BASKET_SUCCESS:
    case UPDATE_BASKET_SUCCESS:
    case ADD_REDEEM_SUCCESS:
    case REMOVE_REDEEM_SUCCESS: {
      const { vouchers } = action.payload;
      const hasVouchers = vouchers && vouchers.length;
      let { mode } = state;
      if (!hasVouchers && state.mode === ACCOUNT_TYPE.REDEEM) {
        mode = ACCOUNT_TYPE.ANONYMOUS;
      } else if (hasVouchers && state.mode !== ACCOUNT_TYPE.B2B) {
        mode = ACCOUNT_TYPE.REDEEM;
      }
      return {
        ...state,
        mode,
      };
    }

    case GET_ORDERS_START:
    case GET_MY_ORDER_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case GET_ORDERS_SUCCESS: {
      const { items, loading } = action.payload;

      return {
        ...state,
        orders: items,
        loading,
      };
    }

    case GET_MY_ORDER_SUCCESS: {
      const { order, loading } = action.payload;
      const { ordersById } = state;

      return {
        ...state,
        ordersById: {
          ...ordersById,
          [order.id]: order,
        },
        loading,
      };
    }

    case GET_ORDERS_DOWNLOAD_STATE_SUCCESS: {
      const { downloadRequests, loading } = action.payload;

      return {
        ...state,
        downloadRequests,
        loading,
      };
    }

    case GET_ORDERS_DOWNLOAD_LINK_SUCCESS: {
      const { uri, loading } = action.payload;

      return {
        ...state,
        uri,
        loading,
      };
    }

    case GET_MY_ORDER_ERROR:
    case GET_ORDERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_ACCOUNT_FIELD: {
      const { name, value } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          [name]: value,
        },
      };
    }

    case SET_DELIVERY_ADDRESS_FIELD: {
      const { name, value } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          deliveryAddressOverride: {
            ...state.formData.deliveryAddressOverride,
            [name]: value,
          },
        },
      };
    }

    case SET_DEPARTMENT_DELIVERY_ADDRESS_FIELD: {
      const { name, value } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          deliveryAddress: {
            ...state.formData.deliveryAddress,
            [name]: value,
          },
        },
      };
    }

    case SET_DEPARTMENT_ADDRESS_FIELD: {
      const { name, value } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          address: {
            ...state.formData.address,
            [name]: value,
          },
        },
      };
    }

    case TOGGLE_DELIVERY_ADDRESS: {
      const {
        formData: { showDeliveryAddress },
      } = state;
      return {
        ...state,
        formData: {
          ...state.formData,
          showDeliveryAddress: !showDeliveryAddress,
        },
      };
    }

    case SET_LOGGED_OUT: {
      const { payload: { mode } = {} } = action;
      return {
        formData: {},
        orders: [],
        presentations: {},
        ordersById: {},
        accounts: [],
        mode: mode || ACCOUNT_TYPE.ANONYMOUS,
      };
    }

    case RESET_DEPARTMENT_FORM: {
      const {
        departmentId,
        countryCode,
        defaultCurrency,
        user: authUser = {},
        countries,
      } = action.payload;

      if (departmentId) {
        const { accounts } = state;
        const account = accounts.find(a => a.departmentId === departmentId);
        return mapDepartment({ account, state, accounts });
      }

      const cc =
        authUser.countryCode &&
        countries.find(({ value }) => value === authUser.countryCode)
          ? authUser.countryCode
          : countryCode;

      const {
        name,
        email,
        phone,
        city,
        postCode,
        line1,
        line2,
        attention,
      } = authUser;

      return {
        ...state,
        formData: {
          nameOverride: name || '',
          emailOverride: email || '',
          phoneNumber: phone || '',
          phoneNumberOverride: phone || '',
          address: {
            city: city || '',
            postCode: postCode || '',
            line1: line1 || '',
            line2: line2 || '',
            attention: attention || '',
            countryCode: cc,
          },
          deliveryAddressOverride: {
            city: city || '',
            postCode: postCode || '',
            line1: line1 || '',
            line2: line2 || '',
            attention: attention || '',
            countryCode: cc,
          },
          defaultCurrency,
          vatCountryCode: cc,
        },
      };
    }

    case SET_DEPARTMENT: {
      const { account } = action.payload;
      const { accounts } = state;
      return mapDepartment({ account, state, accounts });
    }

    default:
      return state;
  }
}
