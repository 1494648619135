// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._19Cqv{padding:20px;text-align:center;font-size:16px;font-weight:400}._3QBCa{text-align:center;margin:4px 10px 20px 10px}._RO8M{display:block !important}.NpTSn{display:none !important}\n", ""]);
// Exports
exports.locals = {
	"content": "_19Cqv",
	"bottom": "_3QBCa",
	"modal": "_RO8M",
	"hideButton": "NpTSn"
};
module.exports = exports;
