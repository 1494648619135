import { getFromState, getMappedUserProfile } from '../helpers';
import { removeAccessToken, getAccessToken } from '../helpers/token';
import { setSalesChannel } from './config';
import {
  GET_LOGGEDIN_USER_START,
  GET_LOGGEDIN_USER_SUCCESS,
  SET_LOGGED_OUT,
  SALES_CHANNELS,
} from '../constants';
import { removeSalesChannelId } from '../helpers/userStorage';

const getLoggedinUser = () => async (
  dispatch,
  getState,
  { fetch, config: { authHost } },
) => {
  dispatch({
    type: GET_LOGGEDIN_USER_START,
    payload: {
      authLoading: true,
    },
  });

  const accessToken = getAccessToken();

  try {
    const response = await fetch(`${authHost}/connect/userinfo`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const profile = await response.json();
    const user = getMappedUserProfile(profile);
    await dispatch(setSalesChannel(SALES_CHANNELS.B2B));
    dispatch({
      type: GET_LOGGEDIN_USER_SUCCESS,
      payload: {
        user,
        subjectId: profile.sub,
        authLoading: false,
      },
    });
  } catch (error) {
    removeAccessToken();
    removeSalesChannelId();
    window.location.href = '/';
  }

  return getFromState(getState(), 'user');
};

const logoutUser = ({ mode } = {}) => () => {
  removeAccessToken();
  removeSalesChannelId();
  return {
    type: SET_LOGGED_OUT,
    payload: {
      mode,
    },
  };
};

export { getLoggedinUser, logoutUser };
