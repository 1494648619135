/* eslint-disable import/prefer-default-export */
import { createIntl, createIntlCache } from 'react-intl';
import {
  SET_LOCALE_START,
  SET_LOCALE_SUCCESS,
  SET_LOCALE_ERROR,
  SET_COUNTRY,
  SET_CURRENCY,
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  SALES_CHANNELS,
  WELCOME_PATH,
  SET_SALES_CHANNEL_SUCCESS,
} from '../constants';

import queryIntl from './queries/intl.graphql';
import {
  getValidCountryCode,
  getValidCurrency,
  getValidLang,
  getPrefixedPathname,
} from '../helpers/intl';
import { updateDocumentLang } from '../DOMUtils';
import {
  getPopular,
  getCategories,
  getCountryPresentations,
  getCountryPresentationsForMessages,
} from './config';
import { setCookie } from '../helpers/cookie';
import { getFromState } from '../helpers';
import { updateBasketItem as updateBasketCurrency } from './basket';
import { formatJsLoggerHelper } from '../helpers/consoleHelpers';

function getIntlFromState(state) {
  const intlFromState = (state && state.intl) || {};
  const { initialNow, locale, messages } = intlFromState;
  const localeMessages = (messages && messages[locale]) || {};

  const intlCache = createIntlCache();
  return createIntl(
    {
      initialNow,
      locale,
      messages: localeMessages,
      defaultLocale: DEFAULT_LOCALE,
      onError: formatJsLoggerHelper,
    },
    intlCache,
  );
}

export function getIntl() {
  return (dispatch, getState) => getIntlFromState(getState());
}

export function setLocale(locale = DEFAULT_LOCALE) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SET_LOCALE_START,
      payload: {
        locale,
      },
    });

    try {
      const { data } = await client.query({
        query: queryIntl,
        variables: {
          expectedLanguage: locale,
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      const messages = data.translatedAllKeyValuePair.reduce(
        (msgs, msg) => ({
          ...msgs,
          [msg.key]: msg.translatedValue,
        }),
        {},
      );

      dispatch({
        type: SET_LOCALE_SUCCESS,
        payload: {
          locale,
          messages,
        },
      });
      await dispatch(
        getCountryPresentations({
          countryCode: getState().intl.countryCode,
          locale,
        }),
      );
      await dispatch(
        getCountryPresentationsForMessages({
          countryCode: getState().intl.countryCode,
          locale,
        }),
      );
      if (process.env.BROWSER) {
        updateDocumentLang(locale);
      }

      return null;
    } catch (error) {
      dispatch({
        type: SET_LOCALE_ERROR,
        payload: {
          locale,
          error,
        },
      });
    }

    return null;
  };
}

export function setCurrency(currency) {
  return async dispatch => {
    const currencyUpper =
      (currency && currency.toUpperCase()) || DEFAULT_CURRENCY.toUpperCase();
    dispatch({
      type: SET_CURRENCY,
      payload: {
        currency: currencyUpper,
      },
    });
    if (process.env.BROWSER) {
      dispatch(
        updateBasketCurrency({
          paymentCurrency: currencyUpper,
          doClearProducts: false,
        }),
      );
    }
  };
}

export function setCountry(countryCode) {
  return async (dispatch, getState) => {
    const {
      user: { mode },
    } = getState();
    const countryCodeUpper =
      (countryCode && countryCode.toUpperCase()) || DEFAULT_COUNTRY;
    await dispatch(getCountryPresentations({ countryCode }));
    await dispatch(getCountryPresentationsForMessages({ countryCode }));
    dispatch({
      type: SET_COUNTRY,
      payload: {
        countryCode: countryCodeUpper,
        mode,
      },
    });
  };
}

export function getDefaultCurrencyForCountry({ countryCode }) {
  return (dispatch, getState) => {
    const {
      config: { salesChannels },
    } = getState();
    const salesChannel = Object.values(salesChannels).find(
      ({ salesFlows }) => salesFlows[countryCode] !== undefined,
    );
    return (
      (salesChannel &&
        salesChannel[countryCode] &&
        salesChannel[countryCode].defaultCurrency) ||
      DEFAULT_CURRENCY
    );
  };
}

export function getDefaultCountryCodeForLocale({ locale }) {
  return (dispatch, getState) => {
    const {
      config: { salesChannels },
    } = getState();
    const salesChannelIds = Object.keys(salesChannels);
    for (let i = 0; i < salesChannelIds.length; i += 1) {
      const countryCodes = Object.keys(
        salesChannels[salesChannelIds[i]].salesFlows,
      );
      for (let j = 0; j < countryCodes.length; j += 1) {
        const salesFlow =
          salesChannels[salesChannelIds[i]].salesFlows[countryCodes[j]];
        if (salesFlow.defaultLocale === locale) {
          return salesFlow.value;
        }
      }
    }
    return DEFAULT_COUNTRY;
  };
}

function getAllCountries({ salesChannels }) {
  const salesChannelIds = Object.values(SALES_CHANNELS);
  let countryCodes = [];
  salesChannelIds.forEach(id => {
    const salesFlows = salesChannels[id] && salesChannels[id].salesFlows;
    if (salesFlows) {
      countryCodes = countryCodes.concat(Object.keys(salesFlows));
    }
  });
  return Array.from(new Set(countryCodes)).map(countryCode => ({
    value: countryCode,
    label: countryCode,
  }));
}

export function getSalesFlowForCountry({ countryCode, salesChannels }) {
  let salesFlow = null;
  Object.values(SALES_CHANNELS).forEach(id => {
    const salesFlows = salesChannels[id] && salesChannels[id].salesFlows;
    if (salesFlows && salesFlows[countryCode.toUpperCase()]) {
      salesFlow = salesFlows[countryCode.toUpperCase()];
    }
  });
  return salesFlow;
}

export function getValidIntl({
  countryCode: requestedCountryCode,
  locale: requestedLocale,
  currency: requestedCurrency,
}) {
  return async (dispatch, getState) => {
    const {
      intl: { locale, currency, countryCode },
      config: { salesChannel, countries, salesChannels },
    } = getState();

    const current = {};
    let all = {};

    current.countryCode = getValidCountryCode({
      countryCodes: [requestedCountryCode],
      countries,
    });

    if (current.countryCode) {
      const country = salesChannel.salesFlows[current.countryCode];
      current.locale = getValidLang({
        languageCodes: [
          requestedLocale,
          (country && country.defaultLocale) || locale,
          DEFAULT_LOCALE,
        ],
        languages: country.supportedLanguages,
      });
      current.currency = getValidCurrency({
        currencyCodes: [
          requestedCurrency,
          country && country.defaultCurrency.toUpperCase(),
          currency,
          DEFAULT_CURRENCY.toUpperCase(),
        ],
        currencies: country.supportedCurrencies,
      });
      all = {
        ...current,
      };
    } else {
      all.countryCode = getValidCountryCode({
        countryCodes: [requestedCountryCode, countryCode, DEFAULT_COUNTRY],
        countries: getAllCountries({ salesChannels }),
      });
      const validCountry = getSalesFlowForCountry({
        countryCode: all.countryCode,
        salesChannels,
      });

      if (validCountry) {
        all.locale = getValidLang({
          languageCodes: [
            requestedLocale,
            validCountry.defaultLocale || locale,
            DEFAULT_LOCALE,
          ],
          languages: validCountry.supportedLanguages,
        });
        all.currency = getValidCurrency({
          currencyCodes: [
            requestedCurrency,
            validCountry.defaultCurrency.toUpperCase(),
            currency,
            DEFAULT_CURRENCY.toUpperCase(),
          ],
          currencies: validCountry.supportedCurrencies,
        });
      }
    }
    await dispatch(
      getCountryPresentations({
        countryCode: getState().intl.countryCode,
        locale,
      }),
    );
    await dispatch(
      getCountryPresentationsForMessages({
        countryCode: getState().intl.countryCode,
        locale,
      }),
    );

    return {
      current,
      all,
    };
  };
}

export function setPath({
  country: requestedCountryCode,
  locale: requestedLocale,
  currency: requestedCurrency,
  pathname: requestedPathname,
  query: requestedSearch,
}) {
  return async (dispatch, getState, { history }) => {
    const {
      intl: { locale, currency, countryCode },
      config: { salesChannelId, salesChannels, countries },
    } = getState();

    const { current } = await dispatch(
      getValidIntl({
        countryCode: requestedCountryCode || countryCode,
        currency: requestedCurrency || (!requestedCountryCode && currency),
        locale: requestedLocale || (!requestedCountryCode && locale),
      }),
    );

    setCookie({ id: 'countryCode', value: current.countryCode?.toUpperCase() });
    setCookie({ id: 'lang', value: current.locale?.toUpperCase() });
    setCookie({ id: 'currency', value: current.currency?.toUpperCase() });

    let pathname = requestedPathname;
    let search = requestedSearch;
    if (process.env.BROWSER) {
      const {
        pathname: browserPathname,
        search: browserSearch,
      } = history.location;
      search = search || browserSearch;
      pathname = pathname || browserPathname;
    }

    pathname = getPrefixedPathname({
      pathname,
      ...current,
    });

    if (
      (current.countryCode && current.countryCode !== countryCode) ||
      (current.locale && current.locale !== locale) ||
      (current.currency && current.currency !== currency)
    ) {
      if (current.countryCode !== countryCode) {
        await dispatch(setCountry(current.countryCode));
        dispatch({
          type: SET_SALES_CHANNEL_SUCCESS,
          payload: {
            countryCode: current.countryCode,
            salesChannelId,
            countries,
          },
        });
      }

      // path to home if country has a B2C saleschannel
      if (salesChannels[SALES_CHANNELS.B2C].salesFlows[current.countryCode]) {
        if (pathname.endsWith(WELCOME_PATH)) {
          pathname = pathname.replace(WELCOME_PATH, '');
        }
      }

      // path to shop if user is on product page and changes country
      if (current.countryCode !== countryCode) {
        const paths = pathname.split('/');
        if (paths.length > 5 && paths[4] === 'shop') {
          pathname = paths.splice(0, paths.indexOf('shop') + 1).join('/');
        }
      }

      if (current.locale !== locale) {
        await dispatch(setLocale(current.locale));
      }

      if (current.currency !== currency) {
        await dispatch(setCurrency(current.currency));
      }
      await dispatch(getCategories());
      await dispatch(getPopular());

      if (process.env.BROWSER) {
        history.push({
          pathname,
          search,
        });
      }
    }

    return getFromState(getState(), 'intl');
  };
}
