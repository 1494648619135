import { toast } from 'react-toastify';
import {
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_START,
  GET_ACCOUNT_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDERS_START,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_DOWNLOAD_START,
  GET_ORDERS_DOWNLOAD_SUCCESS,
  GET_ORDERS_DOWNLOAD_ERROR,
  GET_ORDERS_DOWNLOAD_STATE_SUCCESS,
  GET_ORDERS_DOWNLOAD_STATE_ERROR,
  GET_ORDERS_DOWNLOAD_LINK_SUCCESS,
  GET_ORDERS_DOWNLOAD_LINK_ERROR,
  GET_MY_ORDER_ERROR,
  GET_MY_ORDER_START,
  GET_MY_ORDER_SUCCESS,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_START,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  RESET_DEPARTMENT_FORM,
  SET_DEPARTMENT,
  SET_ACCOUNT_FIELD,
  MY_PATH,
  DEPARTMENT_STATUS,
  ACCOUNT_TYPE,
} from '../constants';
import queryGetMyDepartmentUsers from './queries/getMyDepartmentUsers.graphql';
import queryGetMyOrders from './queries/getMyOrders.graphql';
import queryGetMyOrder from './queries/getMyOrder.graphql';
import mutationUpdateMyAccount from './mutations/updateMyAccount.graphql';
import mutationCreateDepartment from './mutations/createDepartment.graphql';
import mutationCreateOrdersDownload from './mutations/createOrdersDownload.graphql';
import mutationResendOrderConfirmation from './mutations/resendOrderConfirmation.graphql';
import queryGetOrdersDownload from './queries/getOrdersDownload.graphql';
import queryGetOrdersDownloadLink from './queries/getOrdersDownloadLink.graphql';
import { getAccessToken } from '../helpers/token';
import { getFromState } from '../helpers';
import {
  getDepartmentId,
  setDepartmentId,
  getDepartmentBasketId,
} from '../helpers/userStorage';
import { logoutUser } from './loggedinUser';
import { getBasket, createBasket } from './basket';
import { setPath } from './intl';
import { redirectTo } from './config';

import {
  showError,
  accountUpdateStart,
  accountUpdateSuccess,
  resendOrderConfirmationStart,
  resendOrderConfirmationSuccess,
  resendOrderConfirmationError,
} from '../components/Notifications/notificationTypes';

export const setDepartment = ({ departmentId }) => {
  return async (dispatch, getState) => {
    const {
      user: { accounts },
      config: { salesChannelId },
    } = getState();
    const account = accounts.find(a => a.departmentId === departmentId);
    if (account) {
      dispatch({
        type: SET_DEPARTMENT,
        payload: {
          account,
        },
      });
      if (process.env.BROWSER) {
        const departmentBasketId = getDepartmentBasketId({
          departmentId,
        });

        // Create/ get the basket associated with the department here to update basket item counter
        if (!departmentBasketId) {
          await dispatch(setPath({ currency: account.preferredCurrency }));
          await dispatch(createBasket(salesChannelId));
        } else {
          const { paymentCurrency } = await dispatch(
            getBasket(departmentBasketId),
          );
          await dispatch(setPath({ currency: paymentCurrency }));
        }

        setDepartmentId({ departmentId });
      }
    }
  };
};

export function getMyDepartmentAccount() {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_ACCOUNT_START,
      payload: {
        authLoading: true,
      },
    });

    try {
      const {
        data: {
          b2b: { getMyDepartmentUsers },
        },
      } = await client.query({
        query: queryGetMyDepartmentUsers,
        context: {
          fetchPolicy: 'network-only',
        },
      });

      // Users with a blocked department should be logged out
      if (
        getMyDepartmentUsers.find(
          ({ department: { status } }) => status === DEPARTMENT_STATUS.BLOCKED,
        )
      ) {
        await dispatch(logoutUser({ mode: ACCOUNT_TYPE.BLOCKED }));
        return getFromState(getState(), 'user');
      }

      dispatch({
        type: GET_ACCOUNT_SUCCESS,
        payload: {
          accounts: getMyDepartmentUsers,
          authLoading: false,
          initialAuth: true,
        },
      });

      // User belonging to one department
      if (getMyDepartmentUsers.length === 1) {
        await dispatch(
          setDepartment({
            departmentId: getMyDepartmentUsers[0].departmentId,
          }),
        );
        return getFromState(getState(), 'user');
      }

      let id = getDepartmentId();

      // Check if department set
      if (!id) {
        id = getMyDepartmentUsers[0] && getMyDepartmentUsers[0].departmentId;
      }
      // Department id from storage may not exist for another auth user with the same department
      if (
        id &&
        getMyDepartmentUsers.find(
          departmentUser => departmentUser.departmentId === id,
        )
      ) {
        await dispatch(
          setDepartment({
            departmentId: id,
          }),
        );
      }

      return getFromState(getState(), 'user');
    } catch (error) {
      dispatch({
        type: GET_ACCOUNT_ERROR,
        payload: {
          error,
          authLoading: false,
        },
      });
    }

    return getFromState(getState(), 'user');
  };
}
export function getMyOrder({ id, ordersById }) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_MY_ORDER_START,
    });

    const accessToken = getAccessToken();
    if (!accessToken) {
      dispatch({
        type: GET_MY_ORDER_ERROR,
        payload: { error: 'UNAUTHORIZED' },
      });
      return getFromState(getState(), 'user');
    }

    // Check if order already in state
    let order = ordersById[id];

    if (!order) {
      try {
        const {
          data: {
            orders: { orderById },
          },
          errors,
        } = await client.query({
          query: queryGetMyOrder,
          variables: {
            id,
          },
          context: {
            service: 'graphqlHost',
          },
        });

        order = orderById;

        if (errors) {
          dispatch({ type: GET_MY_ORDER_ERROR, payload: { error: errors } });
          return getFromState(getState(), 'user');
        }
      } catch (error) {
        dispatch({
          type: GET_MY_ORDER_ERROR,
          payload: {
            error,
          },
        });
      }
    }

    dispatch({
      type: GET_MY_ORDER_SUCCESS,
      payload: {
        order,
        loading: false,
      },
    });

    return getFromState(getState(), 'user');
  };
}

export function getMyOrders({
  fromCreatedAt,
  toCreatedAt,
  departmentId,
  page,
} = {}) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_ORDERS_START,
    });

    const accessToken = getAccessToken();
    if (!accessToken) {
      dispatch({
        type: GET_ORDERS_ERROR,
        payload: { error: 'UNAUTHORIZED' },
      });
      return getFromState(getState(), 'user');
    }

    const fromCreatedAtInputVariable = fromCreatedAt
      ? fromCreatedAt.toISOString()
      : null;

    let toCreatedAtInputVariable;
    if (toCreatedAt) {
      const toCreatedAtCopy = new Date(toCreatedAt);
      toCreatedAtCopy.setHours(23);
      toCreatedAtCopy.setMinutes(59);
      toCreatedAtCopy.setSeconds(59);
      toCreatedAtCopy.setMilliseconds(999);
      toCreatedAtInputVariable = toCreatedAtCopy.toISOString();
    } else {
      toCreatedAtInputVariable = null;
    }

    try {
      const {
        data: {
          orders: { filter: result },
        },
        errors,
      } = await client.query({
        query: queryGetMyOrders,
        variables: {
          input: {
            fromCreatedAt: fromCreatedAtInputVariable,
            toCreatedAt: toCreatedAtInputVariable,
            b2BDepartmentIds: [departmentId],
          },
          pagination: { page, perPage: 10 },
        },
        context: {
          service: 'graphqlHost',
        },
      });

      if (errors) {
        dispatch({ type: GET_ORDERS_ERROR, payload: { error: errors } });
        return getFromState(getState(), 'user');
      }

      return dispatch({
        type: GET_ORDERS_SUCCESS,
        payload: {
          ...result,
          loading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_ORDERS_ERROR,
        payload: {
          error,
        },
      });
    }

    return getFromState(getState(), 'user');
  };
}

export function getMyOrdersDownload({
  fromCreatedAt,
  toCreatedAt,
  departmentId,
} = {}) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_ORDERS_DOWNLOAD_START,
    });

    const accessToken = getAccessToken();
    if (!accessToken) {
      dispatch({
        type: GET_ORDERS_DOWNLOAD_ERROR,
        payload: { error: 'UNAUTHORIZED' },
      });
      return getFromState(getState(), 'user');
    }

    // set timestamp to last millisecond of day
    toCreatedAt.setHours(23);
    toCreatedAt.setMinutes(59);
    toCreatedAt.setSeconds(59);
    toCreatedAt.setMilliseconds(999);

    try {
      const { data, errors } = await client.mutate({
        mutation: mutationCreateOrdersDownload,
        variables: {
          input: {
            fromCreatedAt: fromCreatedAt.toISOString(),
            toCreatedAt: toCreatedAt.toISOString(),
            departmentIds: [departmentId],
          },
        },
        context: {
          service: 'graphqlHost',
        },
      });

      if (errors) {
        dispatch({
          type: GET_ORDERS_DOWNLOAD_ERROR,
          payload: { error: errors },
        });
        return getFromState(getState(), 'user');
      }

      dispatch({
        type: GET_ORDERS_DOWNLOAD_SUCCESS,
        payload: {
          data,
          loading: false,
        },
      });

      return getFromState(getState(), 'user');
    } catch (error) {
      dispatch({
        type: GET_ORDERS_DOWNLOAD_ERROR,
        payload: {
          error,
        },
      });
    }

    return getFromState(getState(), 'user');
  };
}

export const getMyOrdersDownloadState = () => {
  /* eslint-disable consistent-return */
  return async (dispatch, getState, { client }) => {
    try {
      const {
        data: {
          orders: { downloadRequests },
        },
        errors,
      } = await client.query({
        query: queryGetOrdersDownload,
        context: {
          service: 'graphqlHost',
        },
      });

      if (errors) {
        dispatch({
          type: GET_ORDERS_DOWNLOAD_STATE_ERROR,
          payload: { error: errors },
        });

        return getFromState(getState(), 'user');
      }

      dispatch({
        type: GET_ORDERS_DOWNLOAD_STATE_SUCCESS,
        payload: {
          downloadRequests,
          loading: false,
        },
      });

      return getFromState(getState(), 'user');
    } catch (error) {
      dispatch({
        type: GET_ORDERS_DOWNLOAD_STATE_ERROR,
        payload: {
          error,
        },
      });
    }
  };
};

export const getMyOrdersDownloadLink = ({ name }) => {
  return async (dispatch, getState, { client }) => {
    try {
      const {
        data: {
          orders: {
            downloadLink: { uri },
          },
        },
        errors,
      } = await client.query({
        query: queryGetOrdersDownloadLink,
        variables: {
          input: { name },
        },
        context: {
          service: 'graphqlHost',
        },
      });

      if (errors) {
        dispatch({
          type: GET_ORDERS_DOWNLOAD_LINK_ERROR,
          payload: { error: errors },
        });

        return getFromState(getState(), 'user');
      }

      dispatch({
        type: GET_ORDERS_DOWNLOAD_LINK_SUCCESS,
        payload: {
          uri,
          loading: false,
        },
      });

      return getFromState(getState(), 'user');
    } catch (error) {
      dispatch({
        type: GET_ORDERS_DOWNLOAD_LINK_ERROR,
        payload: {
          error,
        },
      });
    }
  };
};

export const resetAccountForm = ({ departmentId } = {}) => {
  return async (dispatch, getState) => {
    const {
      config: { countries },
      intl: { currency, countryCode },
      loggedinUser: { user } = {},
      user: { accounts },
    } = getState();
    dispatch({
      type: RESET_DEPARTMENT_FORM,
      payload: {
        defaultCurrency: currency,
        countryCode: (user && user.countryCode) || countryCode,
        user,
        countries,
        departmentId,
      },
    });
    // On first account creation set current user country to country from auth
    if (
      process.env.BROWSER &&
      accounts.length === 0 &&
      user &&
      user.countryCode &&
      user.countryCode !== countryCode
    ) {
      await dispatch(setPath({ country: user.countryCode }));
    }
    return getFromState(getState(), 'user');
  };
};

export const setField = ({ name, value, type }) => ({
  type,
  payload: {
    name,
    value,
  },
});

export function createAccount({
  departmentId: failedUpdateDepartmentId,
  vatCountryCode,
  name,
  defaultCurrency,
  address,
  deliveryAddress,
  phoneNumber,
  companyRegistrationId,
  nameOverride,
  phoneNumberOverride,
  deliveryAddressOverride,
}) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CREATE_ACCOUNT_START,
    });

    const accessToken = getAccessToken();
    if (!accessToken) {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        payload: { error: 'Unauthorized' },
      });
      return getFromState(getState(), 'user');
    }
    // createDepartment only allows two values

    let departmentId = failedUpdateDepartmentId;
    // Do not run again if create department was successful but update failed
    if (!departmentId) {
      try {
        const {
          data: {
            b2b: { createDepartment },
          },
        } = await client.mutate({
          mutation: mutationCreateDepartment,
          variables: {
            input: {
              countryCode: address.countryCode,
              companyRegistrationId,
              vatCountryCode,
            },
          },
        });
        departmentId = createDepartment.id;
        // Set department id to disallow editing of companyRegistrationId
        dispatch(
          setField({
            name: 'departmentId',
            value: departmentId,
            type: SET_ACCOUNT_FIELD,
          }),
        );
      } catch (error) {
        dispatch({
          type: CREATE_ACCOUNT_ERROR,
          payload: null,
          error,
        });
        showError(getFromState(getState(), 'error'));
        return getFromState(getState(), 'user');
      }
    }

    // Get the newly created department and more importantly it's autogenerated user
    // Do not change any state
    const {
      data: {
        b2b: { getMyDepartmentUsers },
      },
    } = await client.query({
      query: queryGetMyDepartmentUsers,
      context: {
        fetchPolicy: 'network-only',
      },
    });

    const { id } = getMyDepartmentUsers.find(
      departmentUser => departmentUser.departmentId === departmentId,
    );

    // Now department and user are ready for update
    try {
      const {
        data: {
          b2b: { updateMyDepartment, updateMyDepartmentUser },
        },
      } = await client.mutate({
        mutation: mutationUpdateMyAccount,
        variables: {
          inputDepartment: {
            id: departmentId,
            name,
            phoneNumber,
            defaultCurrency,
            deliveryAddress,
            address,
          },
          inputUser: {
            id,
            nameOverride,
            phoneNumberOverride,
            deliveryAddressOverride,
          },
        },
      });
      dispatch({
        type: CREATE_ACCOUNT_SUCCESS,
        payload: {
          department: updateMyDepartment,
          user: updateMyDepartmentUser,
        },
      });
      dispatch(setDepartment({ departmentId }));
      dispatch(redirectTo({ pathname: MY_PATH, push: true }));
    } catch (error) {
      dispatch({
        type: CREATE_ACCOUNT_ERROR,
        payload: null,
        error,
      });
      showError(getFromState(getState(), 'error'));
    }
    return getFromState(getState(), 'user');
  };
}

export function updateAccount({
  departmentId,
  name,
  phoneNumber,
  defaultCurrency,
  address,
  deliveryAddress,
  id,
  nameOverride,
  phoneNumberOverride,
  deliveryAddressOverride,
}) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: UPDATE_ACCOUNT_START,
    });

    toast.success(accountUpdateStart());

    const accessToken = getAccessToken();
    if (!accessToken) {
      dispatch({
        type: UPDATE_ACCOUNT_ERROR,
        payload: null,
        error: 'UNAUTHORIZED',
      });
      return getFromState(getState(), 'user');
    }

    // Update only allows setting of phone and email if not already set
    // Should already have been set in createAccount
    try {
      const {
        data: { b2b },
      } = await client.mutate({
        mutation: mutationUpdateMyAccount,
        variables: {
          inputDepartment: {
            id: departmentId,
            name,
            defaultCurrency,
            deliveryAddress,
            address,
            phoneNumber,
          },
          inputUser: {
            id,
            nameOverride,
            phoneNumberOverride,
            deliveryAddressOverride,
          },
        },
      });

      const { updateMyDepartment, updateMyDepartmentUser } = b2b;

      dispatch({
        type: UPDATE_ACCOUNT_SUCCESS,
        payload: {
          department: updateMyDepartment,
          user: updateMyDepartmentUser,
        },
      });
      dispatch(setDepartment({ departmentId }));
      dispatch(redirectTo({ pathname: MY_PATH, push: true }));
      toast.success(accountUpdateSuccess());
    } catch (error) {
      dispatch({
        type: UPDATE_ACCOUNT_ERROR,
        payload: null,
        error,
      });
      showError(getFromState(getState(), 'error'));
    }
    return getFromState(getState(), 'user');
  };
}

export function resendOrderConfirmation(orderId) {
  return async (dispatch, getState, { client }) => {
    toast.info(resendOrderConfirmationStart());
    try {
      await client.mutate({
        mutation: mutationResendOrderConfirmation,
        variables: {
          input: {
            orderId,
          },
        },
        context: {
          service: 'graphqlHost',
        },
      });

      toast.success(resendOrderConfirmationSuccess());
    } catch (error) {
      showError({
        errors: [
          {
            message: resendOrderConfirmationError(),
          },
        ],
      });
    }
  };
}
