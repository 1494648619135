import {
  getProductPresentations,
  flattenDeliveryMethods,
  getDate,
  sortDeliveryMethods,
  addCompletelyOutOfStockInfo,
} from '../helpers';
import {
  GET_PRODUCT_SINGLE_SUCCESS,
  GET_PRODUCT_SINGLE_ERROR,
  GET_PRODUCT_SINGLE_START,
  GET_PRODUCT_STOCK_INFO_START,
  GET_PRODUCT_STOCK_INFO_SUCCESS,
  GET_PRODUCT_STOCK_INFO_ERROR,
  SET_FIELD,
  SET_GREETING,
  SET_WRAPPING,
  ADD_PRODUCT_SUCCESS,
  SET_CURRENT_PRODUCT,
  SET_DELIVERY_METHOD,
  SET_CURRENCY_FILTER,
  PRODUCT_TYPE,
} from '../constants';

export default function product(state = {}, action) {
  switch (action.type) {
    case GET_PRODUCT_SINGLE_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PRODUCT_SINGLE_SUCCESS: {
      const {
        data: {
          id,
          presentations,
          deliveryMethods,
          redeemableInCountries,
          disclaimer,
          productType,
        },
        loading,
        countryCode,
      } = action.payload;

      const clearFormData = state.formData.productId !== id;
      const sortedDeliveryMethods = sortDeliveryMethods(deliveryMethods);
      const defaultDeliveryMethod = sortedDeliveryMethods[0].deliveryMethod;
      const deliveryMethodsNormalized = flattenDeliveryMethods(
        sortedDeliveryMethods,
      );
      const defaultDelivery = deliveryMethodsNormalized[defaultDeliveryMethod];
      const productPresentations = getProductPresentations(presentations);
      const {
        sku,
        defaultPrice,
        defaultPriceCurrency,
        price,
      } = defaultDelivery[0];
      const {
        productTitle,
        productLogo,
        productLogoSquare,
        productShortDescription,
        productLongDescription,
        productUspDelivery,
        productUspExpiration,
        productUspCanBeUsed,
        productSlug,
        productMetaTitle,
        productMetaDescription,
      } = productPresentations;

      const gatheredGalleryImages = Object.keys(productPresentations).reduce(
        (array, currKey) => {
          const imagePattern = /productGallery_\d+/;
          const presentationKey = productPresentations[currKey];
          const order = Number(currKey.slice(-1));
          const isGalleryImage = imagePattern.test(currKey);

          if (isGalleryImage) {
            presentationKey[0].order = order;
            array.push(presentationKey[0]);
          }
          return array;
        },
        [],
      );

      gatheredGalleryImages.sort(
        ({ order: aOrder }, { order: bOrder }) => aOrder - bOrder,
      );

      // For "Amount select field" not to have defaut value selected
      // Physical products does not display this select field and need to have price set.
      let defaultCardPrice = '';
      let defaultPurchasePrice = '';
      if (productType === PRODUCT_TYPE.physicalProduct) {
        defaultCardPrice = defaultPrice;
        defaultPurchasePrice = price;
      }

      const minDate = getDate(0);
      const initialValues = {
        productId: id,
        deliveryMethod: defaultDeliveryMethod,
        deliveryDate: minDate,
        giftcardValue: defaultCardPrice,
        valueCurrency: defaultPriceCurrency,
        productPurchasePrice: defaultPurchasePrice,
        stockKeepingUnit: sku,
        quantity: 1,
        countryCode,
      };

      const formData = clearFormData
        ? initialValues
        : Object.assign({}, initialValues, state.formData);

      const logo =
        productLogo && productLogo.length > 0 ? productLogo[0].thumbnail : '';

      const logoSquare =
        productLogoSquare && productLogoSquare.length > 0
          ? productLogoSquare[0]?.thumbnail
          : logo;

      return {
        ...state,
        data: {
          id,
          title: productTitle,
          slug: productSlug,
          logo,
          logoSquare,
          excerpt: productShortDescription,
          disclaimer,
          productType,
          usps: [
            { content: productUspDelivery, icon: 'USP1' },
            {
              content: productUspExpiration,
              icon: 'USP2',
              fill: 'none',
              strokeWidth: '1.5',
              stroke: 'currentColor',
              viewBox: '0 0 24 24',
              pathProps: {
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              },
            },
            { content: productUspCanBeUsed, icon: 'USP3' },
          ],
          description: productLongDescription,
          redeemableInCountries,
          deliveryMethods: deliveryMethodsNormalized,
          gallery: gatheredGalleryImages,
          metaTitle: productMetaTitle,
          metaDescription: productMetaDescription,
        },
        minDate,
        formData,
        loading,
      };
    }

    case GET_PRODUCT_SINGLE_ERROR: {
      const { error, loading } = action.payload;
      return {
        ...state,
        error,
        loading,
        data: {},
      };
    }

    case GET_PRODUCT_STOCK_INFO_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PRODUCT_STOCK_INFO_SUCCESS: {
      const { stockInfo, loading } = action.payload;

      return {
        ...state,
        loading,
        stockInfo: addCompletelyOutOfStockInfo(stockInfo),
      };
    }
    case GET_PRODUCT_STOCK_INFO_ERROR: {
      const { error, loading } = action.payload;
      return {
        ...state,
        error,
        loading,
        stockInfo: {},
      };
    }

    case SET_FIELD: {
      const { name, value } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          [name]: value,
        },
      };
    }

    case SET_CURRENT_PRODUCT: {
      const {
        giftcardValue,
        stockKeepingUnit,
        productPurchasePrice,
        valueCurrency,
      } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          giftcardValue,
          stockKeepingUnit,
          productPurchasePrice,
          valueCurrency,
        },
      };
    }

    case SET_CURRENCY_FILTER: {
      const { currency } = action.payload;
      return {
        ...state,
        currencyFilter: currency,
      };
    }

    case SET_DELIVERY_METHOD: {
      const { deliveryMethod } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          deliveryMethod,
        },
      };
    }

    case SET_GREETING: {
      const { text, base64Image, videoId } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          addGreeting: {
            base64Image,
            text,
            videoId,
          },
        },
      };
    }

    case SET_WRAPPING: {
      const { quantity, id } = action.payload;
      const deleteWrapping = !(quantity || id);
      return {
        ...state,
        formData: {
          ...state.formData,
          addWrapping: deleteWrapping
            ? null
            : {
                quantity,
                id,
              },
        },
      };
    }

    case ADD_PRODUCT_SUCCESS: {
      return {
        ...state,
        formData: {
          ...state.formData,
          // Keep or remove? Better be consistent
          addGreeting: null,
          addWrapping: null,
          // Needs a default but is part of recipient info
          countryCode: state.formData.countryCode,
        },
      };
    }

    default: {
      return state;
    }
  }
}
