import { updateLink } from '../DOMUtils';

// TODO: avoid duplication; ensure sorting of query params, etc.
export const getCanonicalUrl = window => {
  return window.location
    ? window.location.href
    : window.App.config.canonicalUrl;
};

export const setCanonicalUrl = window => {
  updateLink('canonical', getCanonicalUrl(window));
};

export const getHrefLangs = ({ salesFlows, href, locale, countryCode }) => {
  const countryCodeLower = countryCode.toLowerCase();
  const localeLower = locale.toLowerCase();
  return salesFlows.reduce((acc, { countryCode: cc, supportedLanguages }) => {
    const ccLower = cc.toLowerCase();
    supportedLanguages.forEach(lang => {
      const langLower = lang.toLowerCase();
      acc.push({
        lang: `${langLower}-${cc}`,
        href: href.replace(
          `${localeLower}/${countryCodeLower}`,
          `${langLower}/${ccLower}`,
        ),
      });
    });
    return acc;
  }, []);
};
export const getProductIdFromUrl = productUrl => productUrl?.split('/')?.pop();
export default setCanonicalUrl;
