function loadMainClient() {
  const main = require('./client').default; // eslint-disable-line global-require
  main();
}

window.onload = () => {
  if (!global.Intl) {
    // You can show loading banner here

    require.ensure(
      [
        // Add all large polyfills here
        'intl',
        /* @intl-code-template 'intl/locale-data/jsonp/${lang}.js', */
        'intl/locale-data/jsonp/en.js',
        'intl/locale-data/jsonp/da.js',
        /* @intl-code-template-end */
      ],
      require => {
        // and require them here
        require('intl');
        // TODO: This is bad. You should only require one language dynamically
        /* @intl-code-template require('intl/locale-data/jsonp/${lang}.js'); */
        // eslint-disable-next-line import/extensions
        require('intl/locale-data/jsonp/en.js');
        // eslint-disable-next-line import/extensions
        require('intl/locale-data/jsonp/da.js');
        /* @intl-code-template-end */
        loadMainClient();
      },
      'polyfills',
    );
  } else {
    loadMainClient();
  }
};
