// Move this under /helpers
import slugify from 'slugify';
import {
  PK_MAPPINGS,
  PK_MAPPINGS_SORT,
  DELIVERY_METHODS,
  DELIVERY_ORDER,
  URL_EXCEPTION,
  PRODUCT_TYPE,
  voucherCodeWithPrefix,
  SALES_CHANNELS_GROUP,
} from './constants';

import { trimStringProperties } from './helpers/text';
import { isToday } from './helpers/date';

const PHYSICAL_DELIVERY_DAYS_INCREMENT = 3;
const DIGITAL_DELIVERY_HOUR = 8;

export const getMappedUserProfile = profile => {
  const { Email: email, PhoneNumber, LastName, FirstName, Address } = profile;

  // Some versions of auth return Address as serialized json
  const address =
    typeof Address === 'string' ? JSON.parse(Address) : Address || {};

  // Some versions of auth return pascal cased props for Address
  const { countryCode, city, postCode, line1, line2, attention } = Object.keys(
    address,
  ).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.substr(0, 1).toLowerCase() + cur.substr(1)]: address[cur],
    }),
    {},
  );
  return {
    email,
    phone: PhoneNumber ? `+${PhoneNumber}` : null,
    name:
      FirstName || LastName
        ? `${FirstName ? `${FirstName} ` : ''}${LastName || ''}`
        : null,
    countryCode,
    postCode,
    city,
    line1,
    line2,
    attention,
  };
};

export const getFromState = (state, name) => (state && state[name]) || {};

export const ucFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const uniqByProp = (arr, prop) => {
  return arr.filter((obj, pos, res) => {
    return res.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const getPresentation = (array, key, contentKey) =>
  array.length > 0
    ? array.find(({ presentationKey }) => presentationKey === key)[contentKey]
    : 'missing presentation data';

export const updateObjectInArray = (array, action) =>
  array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }

    return {
      ...item,
      ...action.item,
    };
  });

export const addCompletelyOutOfStockInfo = ({ deliveryMethods }) => {
  if (!deliveryMethods) {
    return null;
  }
  return deliveryMethods.map(({ deliveryMethod, skuStockInfo }) => ({
    deliveryMethod,
    skuStockInfo,
    allSkusOutOfStock: skuStockInfo.every(({ inStock }) => !inStock),
  }));
};

export const sortBy = (key, order, locale = 'en') => {
  const reverse = order === 'desc';
  const moveSmaller = reverse ? 1 : -1;
  const moveLarger = reverse ? -1 : 1;

  return (a, b) => {
    if (!a || !b) {
      return 0;
    }
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      if (a[key] < b[key]) return moveSmaller;
      if (a[key] > b[key]) return moveLarger;
      return 0;
    }

    const x = String(a[key]).toLowerCase();
    const y = String(b[key]).toLowerCase();

    const res = x.localeCompare(y, locale, {
      numeric: true,
      sensitivity: 'base',
    });

    if (res < 0) return moveSmaller;
    if (res > 0) return moveLarger;
    return 0;
  };
};

export const getCategoryPresentations = array => {
  const { categoryTranslatedName, categorySlug, ...rest } = array.reduce(
    (acc, item) => {
      const key = item.presentationKey;
      acc[key] =
        item.images && item.images.length > 0 ? item.images : item.textContent;
      return acc;
    },
    {},
  );
  return {
    categorySlug: slugify(categorySlug || categoryTranslatedName || '-', {
      lower: true,
    }),
    categoryTranslatedName,
    ...rest,
  };
};

export const getProductPresentations = array => {
  if (array.length < 1) return { productTitle: 'Your giftcard' };
  const { productSlug, productTitle, ...rest } = array.reduce((acc, item) => {
    const key = item.presentationKey;
    acc[key] =
      item.images && item.images.length > 0 ? item.images : item.textContent;
    return acc;
  }, {});

  return {
    productSlug: slugify(productSlug || productTitle || '-', { lower: true }),
    productTitle,
    ...rest,
  };
};

export const getPagePresentations = array => {
  const retVal = array.reduce((acc, cur) => {
    const pk = cur.presentationKey;
    const mapping = PK_MAPPINGS.find(m => pk.startsWith(m.prefix));
    if (!mapping) {
      acc[pk] = cur.images.length ? cur.images[0].source : cur.textContent;
      return acc;
    }
    const { prefix, attribute, name } = mapping;
    acc[name] = acc[name] || [];
    let id = pk.replace(prefix, '');
    let obj;
    if (id) {
      id = parseInt(id, 10);
      obj = acc[name].find(item => item.id === id);
    }
    if (obj) {
      obj[attribute] = cur.images.length
        ? cur.images[0].source
        : cur.textContent;
    } else {
      acc[name].push({
        id,
        [attribute]: cur.images.length ? cur.images[0].source : cur.textContent,
      });
    }
    return acc;
  }, {});

  Object.keys(retVal).forEach(key => {
    const sortSettings = PK_MAPPINGS_SORT[key];
    if (sortSettings) {
      retVal[key].sort(sortBy(sortSettings.key, sortSettings.order));
    }
  });

  return retVal;
};

export const currencyFormat = currency =>
  currency
    ? {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }
    : undefined;

// https://github.com/facebook/react/issues/15513
// Copy of the Price component get around the above bug

export const priceFormat = ({
  value,
  currency,
  defaultCurrency,
  formatNumber,
  prefix = false,
  parenthesis = false,
  showCurrency = true,
}) => {
  const price = formatNumber(value, {
    ...currencyFormat(showCurrency ? currency || defaultCurrency : null),
  });
  return prefix
    ? `${prefix} ${parenthesis ? '(' : ''}${price}${parenthesis ? ')' : ''}`
    : `${parenthesis ? '(' : ''}${price}${parenthesis ? ')' : ''}`;
};

export const getDate = daysFromToday =>
  new Date(new Date().getTime() + daysFromToday * 86400000);

const formatNumber = (number, opt = {}) => {
  return new Intl.NumberFormat('en-US', { ...opt }).format(number);
};

export const formatPrice = (price, currency) => {
  return priceFormat({
    value: price,
    currency: currency.toLowerCase(),
    defaultCurrency: currency,
    formatNumber,
    prefix: false,
    parenthesis: false,
    showCurrency: true,
  });
};

export const flattenBasketData = data => {
  const flatList = [];
  data.forEach(
    ({
      id,
      deliveryDate,
      deliveryMethod,
      recipientName,
      recipientEmail,
      recipientPhone,
      recipientAddress,
      fees,
      productLines,
      greetings,
      wrappings,
      shippingQuote,
    }) => {
      const today = new Date();
      const dd = new Date(deliveryDate);

      productLines.forEach(({ lineId, ...line }) => {
        const exchangeRate =
          line.priceWithDiscountNoTax / line.paymentAmountWithDiscountNoTax;
        flatList.push({
          id,
          lineId,
          ...line,
          deliveryMethod,
          recipientName,
          recipientEmail,
          recipientPhone,
          recipientAddress,
          deliveryDate: today > dd ? today.toISOString() : deliveryDate,
          greetings,
          wrappings,
          fees,
          shippingQuote,
          ...recipientAddress,
          exchangeRate,
        });
      });
    },
  );
  return flatList;
};

export const flattenDeliveryForms = data =>
  Object.assign(
    {},
    ...data.map(
      ({
        lineId,
        stockKeepingUnit,
        productId,
        deliveryMethod,
        deliveryDate,
        quantity,
        giftcardValue,
        recipientName,
        recipientEmail,
        recipientPhone,
        recipientAddress,
      }) => ({
        [lineId]: {
          stockKeepingUnit,
          productId,
          deliveryMethod,
          deliveryDate,
          quantity,
          giftcardValue,
          recipientName,
          recipientEmail,
          recipientPhone,
          ...recipientAddress,
        },
      }),
    ),
  );

export const flattenDeliveryMethods = data => {
  const deliveryMethods = [];
  data.forEach(({ deliveryMethod, inventoryEntries }) => {
    const amounts = [];
    inventoryEntries.forEach(
      ({
        sku,
        price,
        priceMin,
        priceMax,
        priceStep,
        defaultPriceCurrency,
        defaultPrice,
        defaultPriceMin,
        defaultPriceMax,
        defaultPriceStep,
        attributes,
      }) => {
        if (defaultPrice === null) {
          const options = [];
          for (
            let currentDefaultPrice = defaultPriceMin;
            currentDefaultPrice <= defaultPriceMax;
            currentDefaultPrice += defaultPriceStep
          ) {
            const entry = {
              sku,
              defaultPrice: currentDefaultPrice,
              defaultPriceCurrency,
            };
            options.push(entry);
          }

          const prices = [];
          for (
            let currentPrice = priceMin;
            currentPrice <= priceMax + priceStep / 10; // Safeguard against rounding issues
            currentPrice += priceStep
          ) {
            prices.push(currentPrice);
          }

          options.forEach((option, index) => {
            amounts.push({
              ...option,
              price: prices[index],
              attributes,
            });
          });
        } else {
          amounts.push({
            sku,
            defaultPrice,
            defaultPriceCurrency,
            price,
            attributes,
          });
        }
      },
    );
    deliveryMethods.push({
      [deliveryMethod]: amounts.sort(sortBy('price')),
    });
  });

  return Object.assign({}, ...deliveryMethods);
};

export const flattenRecipientInformation = data => {
  return (
    data &&
    data
      .map(
        ({
          recipientName,
          recipientEmail,
          recipientPhone,
          recipientAddress,
        }) => {
          const ret = {
            recipientName,
            recipientEmail,
            recipientPhone,
          };

          if (recipientAddress) {
            ret.recipientAddress = recipientAddress;
          }

          return ret;
        },
      )
      .reduce((mergedObj, obj) => ({ ...mergedObj, ...obj }), {})
  );
};

export const getNoDayWrapUTCDateString = deliveryDate => {
  const date = deliveryDate ? new Date(deliveryDate) : new Date();
  date.setHours(DIGITAL_DELIVERY_HOUR);
  return date.toISOString();
};

export const normalizeAddress = data => {
  const {
    line1,
    line2,
    attention,
    postCode,
    city,
    countryCode,
    deliveryMethod,
    productPurchasePrice,
    ...product
  } = data;

  delete product.recipientPhoneRepeat;
  delete product.recipientEmailRepeat;

  const recipientAddress = trimStringProperties({
    line1,
    line2,
    attention,
    postCode,
    city,
    countryCode,
  });

  if (deliveryMethod === DELIVERY_METHODS.PHYSICAL) {
    product.deliveryDate = getNoDayWrapUTCDateString(
      getDate(PHYSICAL_DELIVERY_DAYS_INCREMENT),
    );
    return {
      ...product,
      deliveryMethod,
      recipientAddress,
    };
  }

  // If delivery-date is `null`, backend will select an asap date-time
  let newDate = null;

  if (product.deliveryDate && !isToday(product.deliveryDate)) {
    newDate = getNoDayWrapUTCDateString(product.deliveryDate);
  }

  return {
    ...product,
    deliveryDate: newDate,
    deliveryMethod,
  };
};

export const normalizeBuyer = data => {
  // eslint-disable-next-line no-param-reassign
  delete data.copyFromRecipient;
  const { accountId, accountType, name, email, phone, ...addressProps } = data;
  const address = trimStringProperties(addressProps);
  return {
    accountId,
    accountType,
    name,
    email,
    phone,
    address,
  };
};

export const updateBasketItem = (basket, index, values) => {
  const normalizedBasket = basket.map(
    ({
      lineId,
      deliveryDate,
      deliveryMethod,
      giftcardValue,
      productId,
      quantity,
      recipientEmail,
      recipientPhone,
      recipientAddress,
      recipientName,
      stockKeepingUnit,
      valueCurrency,
      greetings = [],
      wrappings = [],
    }) => {
      const greeting = greetings.find(g => g.relatedToProductLine === lineId);
      const wrapping = wrappings.find(w => w.relatedToProductLine === lineId);

      const addWrapping = wrapping
        ? { id: wrapping.wrappingId, quantity: wrapping.quantity }
        : null;

      const addGreeting = greeting
        ? { greetingToken: greeting.greetingToken }
        : null;
      if (lineId !== index) {
        return {
          deliveryDate,
          deliveryMethod,
          giftcardValue,
          productId,
          quantity,
          recipientAddress,
          ...recipientAddress,
          recipientEmail,
          recipientName,
          recipientPhone,
          stockKeepingUnit,
          valueCurrency,
          addGreeting,
          addWrapping,
        };
      }

      return {
        deliveryDate,
        deliveryMethod,
        giftcardValue,
        productId,
        quantity,
        recipientEmail,
        recipientName,
        recipientAddress,
        ...recipientAddress,
        recipientPhone,
        stockKeepingUnit,
        valueCurrency,
        addGreeting,
        addWrapping,
        ...values,
      };
    },
  );

  return normalizedBasket.map(data => normalizeAddress(data));
};

export const updatePhysicalDeliveryAddress = (
  basket,
  physicalProductLineIds,
  newRecipientAddress,
) => {
  const normalizedBasket = basket.map(
    ({
      lineId,
      deliveryDate,
      deliveryMethod,
      giftcardValue,
      productId,
      quantity,
      recipientEmail,
      recipientPhone,
      recipientAddress,
      recipientName,
      stockKeepingUnit,
      valueCurrency,
      greetings = [],
      wrappings = [],
    }) => {
      const greeting = greetings.find(g => g.relatedToProductLine === lineId);
      const wrapping = wrappings.find(w => w.relatedToProductLine === lineId);

      const addWrapping = wrapping
        ? { id: wrapping.wrappingId, quantity: wrapping.quantity }
        : null;

      const addGreeting = greeting
        ? { greetingToken: greeting.greetingToken }
        : null;

      if (physicalProductLineIds.includes(lineId)) {
        return {
          deliveryDate,
          deliveryMethod,
          giftcardValue,
          productId,
          quantity,
          ...recipientAddress,
          ...newRecipientAddress,
          recipientEmail,
          recipientName,
          recipientPhone,
          stockKeepingUnit,
          valueCurrency,
          addGreeting,
          addWrapping,
        };
      }

      return {
        deliveryDate,
        deliveryMethod,
        giftcardValue,
        productId,
        quantity,
        recipientEmail,
        recipientName,
        recipientAddress,
        ...recipientAddress,
        recipientPhone,
        stockKeepingUnit,
        valueCurrency,
        addGreeting,
        addWrapping,
      };
    },
  );

  return normalizedBasket.map(data => normalizeAddress(data));
};

export const removeBasketItem = (basket, index) =>
  basket
    .filter(({ lineId }) => lineId !== index)
    .map(
      ({
        deliveryDate,
        deliveryMethod,
        giftcardValue,
        lineId,
        productId,
        quantity,
        recipientEmail,
        recipientName,
        recipientAddress,
        recipientPhone,
        stockKeepingUnit,
        valueCurrency,
        greetings = [],
        wrappings = [],
      }) => {
        const greeting = greetings.find(g => g.relatedToProductLine === lineId);
        const addGreeting = greeting
          ? { greetingToken: greeting.greetingToken }
          : null;

        const wrapping = wrappings.find(w => w.relatedToProductLine === lineId);

        const addWrapping = wrapping
          ? { id: wrapping.wrappingId, quantity: wrapping.quantity }
          : null;

        return {
          deliveryDate,
          deliveryMethod,
          giftcardValue,
          productId,
          quantity,
          recipientEmail,
          recipientName,
          recipientAddress,
          recipientPhone,
          stockKeepingUnit,
          valueCurrency,
          addGreeting,
          addWrapping,
        };
      },
    );

export const normalizeBasket = (products, billing) => {
  const addProducts = products
    .map(
      ({
        deliveryDate,
        deliveryMethod,
        giftcardValue,
        productId,
        quantity,
        recipientEmail,
        recipientName,
        stockKeepingUnit,
        valueCurrency,
      }) => ({
        deliveryDate,
        deliveryMethod,
        giftcardValue,
        productId,
        quantity,
        recipientEmail,
        recipientName,
        stockKeepingUnit,
        valueCurrency,
      }),
    )
    .map(data => normalizeAddress(data));

  const buyer = normalizeBuyer(billing);

  return { addProducts, buyer };
};

export const stripTrailingSlash = str => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const urlPrefix = (url, { locale, countryCode, currency }) => {
  const result = `/${locale}/${countryCode}/${currency}`.toLowerCase();
  if (URL_EXCEPTION.some(urlexcep => url.includes(urlexcep))) {
    return `${result}${stripTrailingSlash(url)}`;
  }
  return `${result}${stripTrailingSlash(url).toLowerCase()}`;
};

export const getShopParams = ({
  type,
  recipient,
  category,
  occasion,
  brand,
  order,
  currency,
  min,
  max,
}) => {
  const recipients = recipient ? recipient.split(',') : [];
  const categories = category ? category.split(',') : [];
  const occasions = occasion ? occasion.split(',') : [];
  const brands = brand ? brand.split(',') : [];
  const types = type ? type.split(',') : [];

  return {
    filters: {
      type: types,
      recipient: recipients,
      category: categories,
      occasion: occasions,
      brand: brands,
    },
    orderBy: order,
    currency,
    min,
    max,
  };
};

export const createMarkup = data => ({
  __html: data,
});

export const multiplierToPercentage = multiplier =>
  Number(multiplier * 100).toFixed(2);

export const parseProductLine = ({ line, locale }) => {
  const {
    lineId,
    labels,
    giftcardValue,
    quantity,
    valueCurrency,
    total,
    reference,
    totalPaymentDiscountAmount,
    discountPercentage,
    paymentTaxAmount,
    taxRateMultiplier,
    taxType,
    productFeeAmount,
    deliveryMethod,
  } = line;

  const productName = labels[locale] || labels.en;
  const cardValue = formatPrice(giftcardValue, valueCurrency);
  const totalAmount = formatPrice(total, valueCurrency);
  const taxAmountWithPercent = `${formatPrice(
    paymentTaxAmount,
    valueCurrency,
  )} (${multiplierToPercentage(taxRateMultiplier)}% : ${taxType})`;
  const totalDiscount = formatPrice(
    totalPaymentDiscountAmount * quantity,
    valueCurrency,
  );
  const totalFeeAmount = formatPrice(
    productFeeAmount * quantity,
    valueCurrency,
  );

  return {
    lineId,
    productName,
    reference,
    quantity,
    cardValue,
    totalAmount,
    taxAmountWithPercent,
    totalFeeAmount,
    totalDiscount,
    discountPercentage: `${discountPercentage / 100}%`,
    deliveryMethod,
  };
};

export const groupProductLines = ({
  id,
  productLines,
  deliveryMethod,
  deliveryDate,
  fees,
  recipientAddress,
  recipientEmail,
  recipientName,
  recipientPhone,
}) => {
  const orderDetails = {};

  productLines.map(line => {
    const {
      productId,
      giftcardValue,
      stockKeepingUnit,
      labels: { en: productName },
      quantity,
      reference,
      valueCurrency,
      totalPaymentDiscountAmount,
      totalPaymentAmountWithDiscountNoTax,
      labels,
      discountPercentage,
      paymentTaxAmount,
      taxRateMultiplier,
      taxType,
      lineId,
      discountTitles,
    } = line;

    const productIdentifier = `${productName}@${productId}@${stockKeepingUnit}@${giftcardValue}@${deliveryMethod}`;
    const total =
      totalPaymentAmountWithDiscountNoTax + totalPaymentDiscountAmount;
    const lineFees = fees.filter(
      ({ relatedToProductLine }) => relatedToProductLine === lineId,
    )[0];

    if (orderDetails[productIdentifier]) {
      orderDetails[productIdentifier].quantity += quantity;
      orderDetails[productIdentifier].total += total;
      if (lineFees && lineFees !== undefined) {
        orderDetails[productIdentifier].productFees.total +=
          lineFees.totalPaymentAmountWithDiscountNoTax;
        orderDetails[productIdentifier].productFees.tax +=
          lineFees.totalPaymentTaxAmount;
      }
      if (totalPaymentDiscountAmount > 0) {
        orderDetails[
          productIdentifier
        ].discounts.totalPaymentDiscountAmount += totalPaymentDiscountAmount;
      }
    } else {
      const pFees = fees.filter(
        ({ relatedToProductLine }) => relatedToProductLine === lineId,
      );
      const productFees =
        pFees && pFees.length > 0
          ? pFees.map(fee => {
              const pf = {
                labels: fee.labels,
                total: fee.totalPaymentAmountWithDiscountNoTax,
                tax: fee.totalPaymentTaxAmount,
              };
              return pf;
            })[0]
          : {
              labels: [],
              total: 0,
              tax: 0,
            };

      const dFee = fees.filter(
        ({ relatedToProductLine }) => relatedToProductLine === null,
      );
      const deliveryFee =
        dFee && dFee.length > 0
          ? dFee.map(fee => {
              const df = {
                labels: fee.labels,
                lineId: fee.lineId,
                total: fee.totalPaymentAmountWithDiscountNoTax,
                tax: fee.totalPaymentTaxAmount,
              };
              return df;
            })[0]
          : {
              labels: [],
              total: 0,
              tax: 0,
            };

      const deliveryInfo = {
        deliveryMethod,
        deliveryDate,
      };

      const recipient = {
        recipientAddress,
        recipientEmail,
        recipientName,
        recipientPhone,
      };

      const discounts = {
        discountTitles,
        discountPercentage,
        totalPaymentDiscountAmount,
      };

      orderDetails[productIdentifier] = {
        lineId: id,
        productId,
        productName,
        quantity,
        total,
        giftcardValue,
        deliveryInfo,
        labels,
        reference,
        valueCurrency,
        totalPaymentAmountWithDiscountNoTax,
        discounts,
        paymentTaxAmount,
        taxRateMultiplier,
        taxType,
        deliveryFee,
        productFees,
        recipient,
      };
    }

    return line;
  });

  return Object.keys(orderDetails).map(key => orderDetails[key]);
};

export const flatten = arr => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
    );
  }, []);
};

export const isDiff = (o1, o2) => {
  return Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {});
};

export const merge = arr =>
  arr.reduce((mergedObj, obj) => ({ ...mergedObj, ...obj }), {});

export const getTotalQuantity = order =>
  order
    ? order.deliveries.reduce(
        (deliveryAmount, delivery) =>
          deliveryAmount +
          delivery.productLines.reduce(
            (linesAmount, { quantity }) => linesAmount + quantity,
            0,
          ),
        0,
      )
    : 0;

export const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    timerId = setTimeout(boundFunc, delay);
  };
};

export const filterSearchResults = (hits, idsInState) => {
  return hits.filter(hit => idsInState.includes(hit.id));
};

export const sortDeliveryMethods = options => {
  const sorted = [...options].sort((a, b) => {
    const ixA = DELIVERY_ORDER.indexOf(a.value || a.deliveryMethod);
    const ixB = DELIVERY_ORDER.indexOf(b.value || b.deliveryMethod);
    if (ixA > ixB) return 1;
    if (ixB > ixA) return -1;
    return 0;
  });
  return sorted;
};

export const trimWhiteSpace = (string = '') => string.replace(/\s+/g, '');

export const chunkString = (string = '', chunkSize = 3) => {
  const pattern = new RegExp(`.{1,${chunkSize}}`, 'g');
  if (string) {
    return trimWhiteSpace(string)
      .match(pattern)
      .join(' ');
  }
  return '';
};
export const isPhysicalProduct = productType => {
  return productType === PRODUCT_TYPE.physicalProduct;
};
export const isProductPorInPortal = (
  productTitle = '',
  salesChannelGroup = '',
) =>
  productTitle &&
  productTitle.includes('POR') &&
  salesChannelGroup === SALES_CHANNELS_GROUP.PORTAL;

// https://www.regextester.com/106421
// Don't use global flag
// https://stackoverflow.com/a/210077/3779732
const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
export function stringContainsEmoji(s) {
  return emojiRegex.test(s);
}

export const physicalProductFunc = (products, group) => {
  if (products === null && group === 'type') {
    return false;
  }
  const count = products?.filter(
    x => x.productType === PRODUCT_TYPE.physicalProduct,
  ).length;
  return !(
    group === 'type' &&
    count === 0 &&
    !window.location.href.includes('type=')
  );
};

export const isAlphabetic = value =>
  /^[\p{L}\P{N}\P{M}][\p{L}\P{N}\p{M}]+$/u.test(value);

export const isVoucherCodePorOrSgk = code =>
  code.startsWith(voucherCodeWithPrefix.POR) ||
  code.startsWith(voucherCodeWithPrefix.SGK);

export const removeExtraHyphens = code => {
  if (code.startsWith(voucherCodeWithPrefix.POR)) {
    return `${voucherCodeWithPrefix.POR}${code
      .split(voucherCodeWithPrefix.POR)[1]
      .replaceAll('-', '')}`;
  }
  if (code.startsWith(voucherCodeWithPrefix.SGK)) {
    return `${voucherCodeWithPrefix.SGK}${code
      .split(voucherCodeWithPrefix.SGK)[1]
      .replaceAll('-', '')}`;
  }
  if (code.startsWith(voucherCodeWithPrefix.BHN)) {
    return code;
  }
  return code.replaceAll('-', '');
};
